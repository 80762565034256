import { supabase } from '../supabaseClient'
import SimpleIndexedDB from '../SimpleIndexedDB'
import SyncManager from './SyncManager'

export default class HybridStorage {
  constructor(tableName) {
    this.tableName = tableName
    this.localDB = new SimpleIndexedDB('AppDB', tableName)
    this.isUserProfile = tableName === 'user_profiles'
    this.syncInProgress = false
    this.lastSyncTime = null
    this.SYNC_INTERVAL = 1000 * 30 // 30 seconds
    this.setupBroadcastChannel()
  }

  setupBroadcastChannel() {
    if (typeof BroadcastChannel !== 'undefined') {
      try {
        this.channel = new BroadcastChannel('app_sync_channel');
        
        this.channel.onmessage = (event) => {
          if (!event.data || typeof event.data !== 'object') {
            console.log('Received invalid broadcast message:', event.data);
            return;
          }
          
          const { type, data } = event.data;
          
          console.log('Received broadcast message:', type);
          
          try {
            switch (type) {
              case 'SYNC_START':
                console.log('Another tab started sync');
                this.syncInProgress = true;
                break;
                
              case 'SYNC_COMPLETE':
                console.log('Another tab completed sync, refreshing local state');
                this.syncInProgress = false;
                this.lastSyncTime = data?.lastSyncTime;
                this.refreshItems();
                break;
                
              case 'SYNC_ERROR':
                console.log('Sync error in another tab:', data?.error);
                this.syncInProgress = false;
                break;
                
              case 'USER_CHANGED': 
                console.log('User changed in another tab, refreshing session');
                this.initializeSession().then(() => {
                  this.syncWithSupabase().catch(err => {
                    console.error('Error syncing after user change:', err);
                  });
                });
                break;
                
              case 'CLEAR_USER_DATA':
                console.log('Clearing user data from broadcast message');
                if (data?.userId) {
                  this.clearUserData(data.userId);
                }
                break;
                
              case 'ITEM_ADDED':
              case 'ITEM_UPDATED':
              case 'ITEM_DELETED':
                console.log(`Item ${type.split('_')[1].toLowerCase()} in another tab, refreshing`);
                this.refreshItems();
                break;
                
              default:
                console.log('Unknown message type:', type);
            }
          } catch (err) {
            console.error('Error handling broadcast message:', err);
          }
        };
        
        console.log('Broadcast channel set up successfully');
      } catch (error) {
        console.error('Failed to set up broadcast channel:', error);
        this.channel = null;
      }
    } else {
      console.log('BroadcastChannel not supported in this browser, falling back to localStorage');
    }
  }

  broadcastMessage(type, data = {}) {
    if (this.channel) {
      this.channel.postMessage({ type, data });
    } else if (typeof localStorage !== 'undefined') {
      // Fallback for browsers that don't support BroadcastChannel
      const message = JSON.stringify({ type, data, timestamp: Date.now() });
      localStorage.setItem('app_sync_message', message);
      // Remove after a short delay to trigger storage events in other tabs
      setTimeout(() => {
        localStorage.removeItem('app_sync_message');
      }, 100);
    }
  }

  async refreshItems() {
    try {
      // Refresh items from the local database
      console.log('Refreshing items from local database');
      const refreshEvent = new Event('app_data_refresh');
      window.dispatchEvent(refreshEvent);
    } catch (error) {
      console.error('Error refreshing items:', error);
    }
  }

  async clearUserData(userId) {
    try {
      console.log('Clearing user data for user:', userId);
      // Get all items
      const allItems = await this.localDB.getAllItems();
      
      // Delete user-specific items
      const userItems = allItems.filter(item => item.user_id === userId);
      for (const item of userItems) {
        await this.localDB.deleteItem(item.id);
      }
      
      console.log(`Cleared ${userItems.length} items for user ${userId}`);
      
      // Notify app of data change
      this.refreshItems();
    } catch (error) {
      console.error('Error clearing user data:', error);
    }
  }

  async initialize() {
    try {
      const initStartTime = Date.now();
      let initialized = false;
      let initAttempt = 0;
      const MAX_INIT_ATTEMPTS = 5;
      const INIT_TIMEOUT = 8000; // 8 seconds timeout
      const LOCK_STALE_TIMEOUT = 10000; // 10 seconds
      
      // Generate a unique ID for this initialization attempt
      const initId = Math.random().toString(36).substring(2, 15);
      
      // Clear any stale locks
      if (typeof localStorage !== 'undefined') {
        const isInitializing = localStorage.getItem('app_db_initializing');
        const initTime = localStorage.getItem('app_db_init_time');
        const initBy = localStorage.getItem('app_db_init_by');
        
        if (isInitializing === 'true' && initTime) {
          const lockTime = parseInt(initTime);
          const currentTime = Date.now();
          const lockAge = currentTime - lockTime;
          
          if (lockAge > LOCK_STALE_TIMEOUT) { // Clear locks older than 10 seconds
            console.log(`Found a stale lock (${lockAge}ms old, init by ${initBy}), clearing it`);
            localStorage.removeItem('app_db_initializing');
            localStorage.removeItem('app_db_init_time');
            localStorage.removeItem('app_db_init_by');
          }
        }
      }
      
      // Function to acquire the initialization lock
      const acquireLock = () => {
        if (typeof localStorage !== 'undefined') {
          const isAlreadyInitializing = localStorage.getItem('app_db_initializing') === 'true';
          const initTime = parseInt(localStorage.getItem('app_db_init_time') || '0');
          const lockAge = Date.now() - initTime;
          
          // If another tab is initializing and the lock is not stale, return false
          if (isAlreadyInitializing && lockAge < LOCK_STALE_TIMEOUT) {
            return false;
          }
          
          // Otherwise, we can acquire the lock
          localStorage.setItem('app_db_initializing', 'true');
          localStorage.setItem('app_db_init_time', Date.now().toString());
          localStorage.setItem('app_db_init_by', initId);
          return true;
        }
        return true; // Default to true if localStorage not available
      };
      
      // Function to release the lock, but only if we own it
      const releaseLock = () => {
        if (typeof localStorage !== 'undefined') {
          const initBy = localStorage.getItem('app_db_init_by');
          if (initBy === initId) {
            localStorage.removeItem('app_db_initializing');
            localStorage.removeItem('app_db_init_time');
            localStorage.removeItem('app_db_init_by');
          }
        }
      };
      
      // Wait for other initialization to complete
      const waitForOtherInit = async () => {
        let waited = 0;
        const CHECK_INTERVAL = 500;
        const MAX_WAIT = 5000;
        
        while (waited < MAX_WAIT) {
          const isInitializing = localStorage.getItem('app_db_initializing') === 'true';
          if (!isInitializing) {
            console.log('Other initialization completed, proceeding...');
            return true;
          }
          
          console.log(`Waiting for other initialization (${waited}ms)...`);
          await new Promise(resolve => setTimeout(resolve, CHECK_INTERVAL));
          waited += CHECK_INTERVAL;
        }
        
        console.warn('Timed out waiting for other initialization');
        return false;
      };
      
      while (!initialized && initAttempt < MAX_INIT_ATTEMPTS) {
        try {
          initAttempt++;
          console.log(`Attempting to initialize database (attempt ${initAttempt} of ${MAX_INIT_ATTEMPTS}, id: ${initId})`);
          
          // Try to acquire the lock
          const lockAcquired = acquireLock();
          
          if (!lockAcquired) {
            console.log(`Another tab is already initializing (attempt ${initAttempt}), waiting...`);
            await waitForOtherInit();
            
            // After waiting, check if the database is already open in this tab
            if (this.localDB && this.localDB.db) {
              console.log('Database already initialized while waiting');
              initialized = true;
              break;
            }
            
            continue;
          }
          
          console.log(`Lock acquired (${initId}), initializing database...`);
          
          try {
            // Open the database with a timeout
            const openTimeout = new Promise((_, reject) => 
              setTimeout(() => reject(new Error('Database open timed out')), INIT_TIMEOUT)
            );
            
            let db;
            try {
              db = await Promise.race([
                this.localDB.openDB(),
                openTimeout
              ]);
            } catch (dbError) {
              console.error('Error opening database, attempting to reset:', dbError);
              
              // Try to reset the database if we can't open it
              try {
                db = await this.localDB.resetDatabase();
                console.log('Database reset and recreated successfully');
              } catch (resetError) {
                console.error('Error resetting database:', resetError);
                throw resetError;
              }
            }
            
            if (!db) {
              throw new Error('Failed to open database, null result');
            }
            
            // Check if the store exists in the database
            if (!db.objectStoreNames.contains(this.tableName)) {
              console.warn(`Store ${this.tableName} not found, recreating database`);
              db = await this.localDB.resetDatabase();
            }
            
            // Initialize the session
            const session = await this.initializeSession();
            
            // If user is logged in, initialize sync manager
            if (session?.user) {
              console.log('Session found, initializing sync manager');
              try {
                this.syncManager = new SyncManager(this.localDB, this.tableName);
                await this.syncManager.initialize();
                
                // Add a slight delay before starting sync to avoid contention
                const randomDelay = Math.floor(Math.random() * 2000) + 1000;
                setTimeout(() => {
                  this.syncWithSupabase().catch(err => {
                    console.error('Error during initial sync:', err);
                  });
                }, randomDelay);
              } catch (syncError) {
                console.error('Error initializing sync manager:', syncError);
                // Continue without SyncManager in case of error
              }
            } else {
              console.log('No active session, skipping Supabase initialization');
            }
            
            // Success! Database is initialized
            initialized = true;
            
            // Dispatch event to notify that the database has been initialized
            if (typeof window !== 'undefined') {
              const event = new Event('app_db_initialized');
              window.dispatchEvent(event);
            }
          } catch (dbError) {
            console.error(`Database initialization error (attempt ${initAttempt}):`, dbError);
            
            // If we've reached max attempts, propagate the error
            if (initAttempt >= MAX_INIT_ATTEMPTS) {
              throw dbError;
            }
            
            // Add a small delay before retrying
            await new Promise(resolve => setTimeout(resolve, 1000));
          } finally {
            // Release the lock only if we succeeded or if this is the last attempt
            if (initialized || initAttempt >= MAX_INIT_ATTEMPTS) {
              releaseLock();
            }
          }
        } catch (attemptError) {
          console.error(`Error in initialization attempt ${initAttempt}:`, attemptError);
          
          // On the last attempt, make sure to release the lock
          if (initAttempt >= MAX_INIT_ATTEMPTS) {
            releaseLock();
            throw attemptError;
          }
        }
      }
      
      // Safety check - force initialization after timeout even if not successful
      if (!initialized && Date.now() - initStartTime > 15000) { // 15 seconds total timeout
        console.warn('Initialization taking too long, forcing completion');
        initialized = true;
        releaseLock();
        
        // Notify app of timeout
        if (typeof window !== 'undefined') {
          const timeoutEvent = new CustomEvent('app_db_timeout', { 
            detail: { message: 'Database initialization timed out, some features may not be available' } 
          });
          window.dispatchEvent(timeoutEvent);
        }
      }
      
      // Listen for storage events from other tabs
      window.addEventListener('storage', (event) => {
        if (event.key === 'app_user_changed' && event.newValue) {
          // User changed in another tab, refresh our data
          console.log('User changed in another tab, refreshing data...');
          this.initializeSession().then(() => {
            this.syncWithSupabase().catch(err => {
              console.error('Error syncing after user change:', err);
            });
          });
        }
      });
      
      console.log(`Database initialization completed successfully (id: ${initId})`);
      return true;
    } catch (error) {
      console.error('Error in initialize:', error);
      
      // Dispatch an error event
      if (typeof window !== 'undefined') {
        const errorEvent = new CustomEvent('app_db_error', { 
          detail: { message: error.message, error: error }
        });
        window.dispatchEvent(errorEvent);
      }
      
      throw error;
    }
  }

  async initializeSession() {
    const { data: { session } } = await supabase.auth.getSession();
    this.session = session;
    
    if (!session) {
      console.log('No active session');
      return null;
    }

    return session;
  }

  startPeriodicSync() {
    // Set up a single event listener for changes to avoid multiple listeners
    if (typeof window !== 'undefined' && !this._syncEventListenerAttached) {
      this._syncEventListenerAttached = true;
      
      // Create a debounced version of syncWithSupabase
      let syncTimeout = null;
      const debouncedSync = () => {
        if (syncTimeout) {
          clearTimeout(syncTimeout);
        }
        
        syncTimeout = setTimeout(() => {
          if (!this.syncInProgress && this.shouldSync()) {
            this.syncWithSupabase();
          }
        }, 1000); // 1 second debounce
      };
      
      // Set up event listeners for common user actions that should trigger sync
      window.addEventListener('focus', debouncedSync);
      window.addEventListener('visibilitychange', () => {
        if (document.visibilityState === 'visible') {
          debouncedSync();
        }
      });
      
      // Also set up a fallback interval for long-running sessions
      // but make it much less frequent than before (5 minutes)
      setInterval(() => {
        if (!this.syncInProgress && this.shouldSync()) {
          console.log('Performing fallback sync after interval');
          this.syncWithSupabase();
        }
      }, 5 * 60 * 1000); // 5 minutes
    }
  }

  shouldSync() {
    // We should sync if:
    // 1. We've never synced before
    // 2. It's been at least 30 seconds since the last sync
    // 3. There are pending changes
    
    if (!this.lastSyncTime) return true;
    
    const timeSinceLastSync = Date.now() - this.lastSyncTime;
    return timeSinceLastSync >= this.SYNC_INTERVAL;
  }

  async syncWithSupabase() {
    // If already syncing, don't start another sync
    if (this.syncInProgress) {
      console.log('Sync already in progress, skipping...');
      return;
    }

    try {
      // Check if user is authenticated
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user) {
        console.log('No authenticated user, skipping sync with Supabase');
        return;
      }

      console.log('Starting sync with Supabase');
      this.syncInProgress = true;
      this.broadcastMessage('SYNC_START');

      // First, fetch changes from Supabase
      await this.fetchChangesFromSupabase(session.user.id);

      // Then, push pending changes to Supabase if we have a sync manager
      if (this.syncManager) {
        try {
          await this.syncManager.performSync();
        } catch (syncError) {
          console.error('Error in sync manager performSync:', syncError);
          // Continue with the rest of the sync process even if this part fails
        }
      }

      // Update sync status
      this.lastSyncTime = new Date();
      
      console.log('Sync completed at', this.lastSyncTime);
      
      // Notify other tabs that sync is complete
      this.broadcastMessage('SYNC_COMPLETE', { lastSyncTime: this.lastSyncTime });
      
      // Dispatch an event to notify app components that data has been synced
      if (typeof window !== 'undefined') {
        try {
          const syncEvent = new Event('app_data_synced');
          window.dispatchEvent(syncEvent);
        } catch (eventError) {
          console.error('Error dispatching sync event:', eventError);
        }
      }
    } catch (error) {
      console.error('Error during sync:', error);
      this.broadcastMessage('SYNC_ERROR', { error: error.message });
    } finally {
      // Always make sure to clear the sync flag
      this.syncInProgress = false;
    }
  }

  async fetchChangesFromSupabase(userId) {
    try {
      console.log('Fetching changes from Supabase for user', userId);
      
      // Get last sync time from local DB or use a default (1 day ago)
      const metadata = await this.getLastSyncMetadata();
      const lastSyncTime = metadata?.lastSyncTime || new Date(Date.now() - 86400000).toISOString();
      
      console.log('Last sync time:', lastSyncTime);
      
      // Fetch all records for the current user without filtering by update time
      const { data, error } = await supabase
        .from(this.tableName)
        .select('*')
        .eq('user_id', userId);
      
      if (error) {
        console.error('Error fetching changes from Supabase:', error);
        return;
      }
      
      console.log(`Found ${data.length} items from Supabase`);
      
      // Update or add each item in the local DB
      for (const item of data) {
        try {
          // Convert snake_case to camelCase for local DB
          const localItem = this.transformToLocalFormat(item);
          
          // Check if item exists locally
          const existingItem = await this.localDB.getItem(item.id);
          
          if (existingItem) {
            // Only update if we don't have a version or server version is newer
            if (!existingItem.version || (item.version && item.version > existingItem.version)) {
              await this.localDB.updateItem({
                ...localItem,
                syncStatus: 'synced'
              });
            }
          } else {
            // Add new item
            await this.localDB.addItem({
              ...localItem,
              syncStatus: 'synced'
            });
          }
        } catch (itemError) {
          console.error(`Error processing item ${item.id}:`, itemError);
          // Continue with other items
        }
      }
      
      // Update last sync metadata
      await this.updateSyncMetadata();
      
      return data;
    } catch (error) {
      console.error('Error fetching changes from Supabase:', error);
      throw error;
    }
  }

  async getLastSyncMetadata() {
    try {
      // Try to get the existing sync metadata
      const allItems = await this.localDB.getAllItems();
      const metadata = allItems.find(item => item.type === 'syncMetadata');
      
      return metadata;
    } catch (error) {
      console.error('Error getting last sync metadata:', error);
      return null;
    }
  }

  async updateSyncMetadata() {
    try {
      const allItems = await this.localDB.getAllItems();
      const existingMetadata = allItems.find(item => item.type === 'syncMetadata');
      
      const newMetadata = {
        type: 'syncMetadata',
        lastSyncTime: new Date().toISOString(),
        id: existingMetadata ? existingMetadata.id : crypto.randomUUID()
      };
      
      if (existingMetadata) {
        await this.localDB.updateItem(newMetadata);
      } else {
        await this.localDB.addItem(newMetadata);
      }
    } catch (error) {
      console.error('Error updating sync metadata:', error);
    }
  }

  // Helper method to transform Supabase format to local format
  transformToLocalFormat(item) {
    // This is a simplified version - expand as needed for your specific fields
    return {
      ...item,
      // Convert specific snake_case fields to camelCase
      uploadDateTime: item.upload_date_time,
      shortName: item.short_name,
      servingSize: item.serving_size,
      confidenceScore: item.confidence_score,
      exerciseEquivalent: item.exercise_equivalent,
      allergyWarning: item.allergy_warning,
      mealCategory: item.meal_category,
      // Add additional necessary conversions here
    };
  }

  async addItem(data) {
    try {
      // Always get the current session to ensure we have the latest user information
      const { data: { session } } = await supabase.auth.getSession();
      const userId = session?.user?.id;
      
      // For user profiles, we need special handling
      if (data.type === 'userProfile') {
        console.log('Adding/updating user profile');
        
        if (!userId) {
          console.error('Cannot add user profile: No authenticated user');
          throw new Error('User must be authenticated to add a profile');
        }
        
        // Ensure user_id is set
        data.user_id = userId;
        
        // Check if profile already exists for this user
        const existingProfile = await this.getProfileByUserId(userId);
        
        if (existingProfile) {
          console.log('Profile exists, updating instead of adding');
          return await this.updateItem({
            ...existingProfile,
            ...data
          });
        }
      }
      
      // Make a copy of the data to avoid modifying the original
      const itemToAdd = { ...data };
      
      // If item doesn't have a user_id but the user is authenticated, add user_id
      if (userId && !itemToAdd.user_id) {
        itemToAdd.user_id = userId;
        console.log('Setting user_id for item:', userId);
      } else if (!userId) {
        console.log('No authenticated user, item will be stored locally only');
      }
      
      // If there's an imageBlob, convert it to base64 for Supabase
      if (itemToAdd.imageBlob) {
        try {
          const base64 = await this.blobToBase64(itemToAdd.imageBlob);
          itemToAdd.image_base64 = base64;
        } catch (imgError) {
          console.error('Error converting blob to base64:', imgError);
        }
      }
      
      // Generate a random UUID for the item if not provided
      if (!itemToAdd.id) {
        itemToAdd.id = crypto.randomUUID();
      }
      
      // Add the item to the local database
      const id = await this.localDB.addItem(itemToAdd);
      
      // If the user is authenticated, queue the item for syncing to Supabase
      if (userId && this.syncManager) {
        // Start a sync to upload the item to Supabase
        this.syncManager.queueChange({
          id: itemToAdd.id,
          operation: 'create', 
          data: itemToAdd,
          timestamp: new Date().toISOString()
        });
        
        // Trigger an immediate sync if not in progress
        this.triggerSync();
      }
      
      return id;
    } catch (error) {
      console.error('Error in addItem:', error);
      throw error;
    }
  }

  // Helper method to get profile by user ID
  async getProfileByUserId(userId) {
    try {
      const allItems = await this.localDB.getAllItems();
      return allItems.find(item => 
        item.type === 'userProfile' && item.user_id === userId
      );
    } catch (error) {
      console.error('Error getting profile by user ID:', error);
      return null;
    }
  }

  async getItem(id) {
    return await this.localDB.getItem(id)
  }

  async getAllItems() {
    try {
      // Get the current user's ID
      const { data: { session } } = await supabase.auth.getSession();
      const userId = session?.user?.id;
      
      // Get all items from local DB
      const allItems = await this.localDB.getAllItems();
      
      // Filter items by user_id if we have an authenticated user
      // Exception: type='userProfile' items are always returned regardless of user_id
      if (userId) {
        return allItems.filter(item => 
          (item.user_id === userId) || 
          (item.type === 'userProfile') || 
          (item.type === 'syncMetadata') ||
          (item.type === 'savedRecipes' && item.user_id === userId)
        );
      } else {
        // For unauthenticated users (trial mode), only return items without user_id
        return allItems.filter(item => !item.user_id);
      }
    } catch (error) {
      console.error('Error in getAllItems:', error);
      return [];
    }
  }

  async updateItem(data) {
    try {
      console.log('Updating item with data:', data)
      const itemWithSync = {
        ...data,
        syncStatus: 'pending',
        operation: 'update',
        version: (data.version || 0) + 1,
        uploadDateTime: new Date().toISOString()
      }
      console.log('Item with sync status:', itemWithSync)
      await this.localDB.updateItem(itemWithSync)
      if (this.syncManager) {
        await this.syncManager.queueChange({
          ...itemWithSync,
          operation: 'update'
        })
        
        // Trigger immediate sync
        this.triggerSync();
      }
      return `Item with ID ${data.id} updated successfully.`
    } catch (error) {
      console.error('Error updating item:', error)
      throw error
    }
  }

  async deleteItem(id) {
    try {
        const existingItem = await this.localDB.getItem(id);
        if (!existingItem) {
            throw new Error(`Item with ID ${id} not found`);
        }

        // Create a delete record
        const deleteRecord = {
            id,
            operation: 'delete',
            syncStatus: 'pending',
            uploadDateTime: new Date().toISOString(),
            version: (existingItem.version || 0) + 1
        };
        
        // Queue the delete operation first
        if (this.syncManager) {
            console.log('Queueing delete operation for item:', id);
            await this.syncManager.queueChange(deleteRecord);
            
            // Trigger immediate sync
            this.triggerSync();
        }
        
        // Mark the item as pending delete in local DB
        await this.localDB.updateItem({
            ...existingItem,
            operation: 'delete',
            syncStatus: 'pending'
        });
        
        return `Item with ID ${id} queued for deletion.`;
    } catch (error) {
        console.error('Error deleting item:', error);
        throw error;
    }
  }

  async blobToBase64(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  async storeUserProfile(profile) {
    try {
      // Store in IndexedDB with type for local use
      const existingItems = await this.localDB.getAllItems();
      const existingProfile = existingItems.find(item => item.type === 'userProfile');
      
      // Make sure we have the type for local storage
      const localProfile = { ...profile };
      if (!localProfile.type) {
        localProfile.type = 'userProfile';
      }
      
      if (existingProfile) {
        await this.localDB.updateItem({
          ...existingProfile,
          ...localProfile
        });
      } else {
        await this.localDB.addItem(localProfile);
      }
    } catch (error) {
      console.error('Error storing user profile:', error);
      throw error;
    }
  }

  async getUserProfile() {
    try {
      const items = await this.localDB.getAllItems();
      return items.find(item => item.type === 'userProfile');
    } catch (error) {
      console.error('Error getting user profile:', error);
      return null;
    }
  }

  async updateUserProfile(profile) {
    try {
        // Don't store user profiles in the food_items table
        // Instead, directly update Supabase
        const { data: { session } } = await supabase.auth.getSession();
        if (!session?.user?.id) {
            throw new Error('No authenticated user');
        }

        // Make sure name is included
        if (!profile.name) {
            throw new Error('Name is required for user profile');
        }

        // Create a copy without the type field for Supabase
        const supabaseProfileData = { ...profile };
        if ('type' in supabaseProfileData) {
            delete supabaseProfileData.type; // Remove type as it's not in the DB schema
        }

        const { data, error } = await supabase
            .from('user_profiles')
            .upsert({
                ...supabaseProfileData,
                user_id: session.user.id
            })
            .select()
            .single();

        if (error) throw error;
        
        // Broadcast profile update to other tabs
        this.broadcastMessage('profile_updated', { profileId: data.id });
        
        return data;
    } catch (error) {
        console.error('Error updating user profile:', error);
        throw error;
    }
  }

  // Method to trigger sync manually (call this after adding, updating, or deleting items)
  triggerSync() {
    if (!this.syncInProgress) {
      this.syncWithSupabase();
    }
  }

  async getWeeklyData(monday, sunday) {
    try {
      // Get the current user's ID
      const { data: { session } } = await supabase.auth.getSession();
      const userId = session?.user?.id;
      
      // Get all items from local DB
      const allItems = await this.localDB.getAllItems();
      
      // Filter by date range and user_id
      const weeklyItems = allItems.filter(item => {
        // Skip non-food items
        if (item.type === 'userProfile' || item.type === 'syncMetadata' || item.type === 'savedRecipes') {
          return false;
        }
        
        // Check user ownership
        if (userId && item.user_id && item.user_id !== userId) {
          return false;
        }
        
        // Check date range
        if (!item.uploadDateTime && !item.upload_date_time) {
          return false;
        }
        
        const itemDate = new Date(item.uploadDateTime || item.upload_date_time);
        return itemDate >= monday && itemDate <= sunday;
      });
      
      return weeklyItems;
    } catch (error) {
      console.error('Error in getWeeklyData:', error);
      return [];
    }
  }
}