/*
 * The wrapper for HybridStorage for persistency across every component 
 * By using "const db = useDB", you will get a HybridStorage object for use
 */
import React, { createContext, useState, useContext, useEffect } from 'react';
import HybridStorage from '../services/HybridStorage';
import { supabase } from '../supabaseClient';

// Create the context 
const DBContext = createContext(null);

// Export a hook to use the DBContext easily in components
export const useDB = () => {
  return useContext(DBContext);
};

// The DBProvider component to wrap your application and provide the database instance
export const DBProvider = ({ children }) => {
  const [dbInstance, setDbInstance] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [error, setError] = useState(null);
  const [initAttempt, setInitAttempt] = useState(0);

  // Function to initialize the database
  const initDB = async () => {
    try {
      console.log(`Initializing database (attempt ${initAttempt + 1})`);
      
      const storage = new HybridStorage('food_items');
      
      // Add the checkUserProfile method to the HybridStorage instance
      storage.checkUserProfile = async () => {
        const { data: { session } } = await supabase.auth.getSession();
        if (!session?.user?.id) return null;

        try {
          // First check local database for the profile
          if (storage.localDB && storage.localDB.db) {
            const allItems = await storage.localDB.getAllItems();
            const profile = allItems.find(item => 
              item.type === 'userProfile' && item.user_id === session.user.id
            );
            
            if (profile) {
              console.log('Found profile in local database');
              return profile;
            }
          }
          
          // If not found locally, check Supabase
          console.log('Checking Supabase for user profile');
          const { data, error } = await supabase
            .from('user_profiles')
            .select('*')
            .eq('user_id', session.user.id)
            .single();

          if (error && error.code !== 'PGRST116') {
            console.error('Error checking user profile:', error);
            return null;
          }

          // If found in Supabase, store it locally for next time
          if (data && storage.localDB && storage.localDB.db) {
            try {
              // Add type field only for local storage
              const localProfileData = {
                ...data,
                type: 'userProfile'
              };
              await storage.localDB.addItem(localProfileData);
              console.log('Stored Supabase profile in local database');
            } catch (dbError) {
              console.error('Error storing profile in local DB:', dbError);
            }
          }

          return data;
        } catch (err) {
          console.error('Error checking user profile:', err);
          return null;
        }
      };
      
      // Initialize the storage
      await storage.initialize();
      setDbInstance(storage);
      
      // Start periodic sync
      storage.startPeriodicSync();
      
      // Dispatch an event to notify that the database has been initialized
      if (typeof window !== 'undefined') {
        const event = new Event('app_db_initialized');
        window.dispatchEvent(event);
      }
      
      setIsInitialized(true);
    } catch (err) {
      console.error('Failed to initialize database:', err);
      setError(err);
      
      // Dispatch an event for the error
      if (typeof window !== 'undefined') {
        const errorEvent = new CustomEvent('app_db_error', { detail: err });
        window.dispatchEvent(errorEvent);
      }
      
      // Increment the attempt counter
      setInitAttempt(prev => prev + 1);
    }
  };

  // Effect to initialize the database
  useEffect(() => {
    // Only try to initialize if we haven't succeeded yet
    if (!isInitialized && initAttempt < 3) {
      initDB();
    } else if (initAttempt >= 3 && !isInitialized) {
      console.error('Max initialization attempts reached');
      setIsInitialized(true); // Force initialization to prevent further attempts
      
      // Dispatch a timeout event
      if (typeof window !== 'undefined') {
        const timeoutEvent = new CustomEvent('app_db_timeout', { 
          detail: { message: 'Database initialization failed after multiple attempts' } 
        });
        window.dispatchEvent(timeoutEvent);
      }
    }
    
    // Listen for check DB state events
    const handleCheckDBState = () => {
      if (!isInitialized && initAttempt < 3) {
        console.log('Received request to check DB state, attempting initialization');
        initDB();
      }
    };
    
    window.addEventListener('app_check_db_state', handleCheckDBState);
    
    return () => {
      window.removeEventListener('app_check_db_state', handleCheckDBState);
    };
  }, [isInitialized, initAttempt]);

  // Clear database locks on unmount
  useEffect(() => {
    return () => {
      // Clear any locks when the component unmounts
      if (typeof localStorage !== 'undefined') {
        const initBy = localStorage.getItem('app_db_init_by');
        // Only clear if this component initialized it
        if (initBy === window.sessionStorage.getItem('app_instance_id')) {
          localStorage.removeItem('app_db_initializing');
          localStorage.removeItem('app_db_init_time');
          localStorage.removeItem('app_db_init_by');
        }
      }
    };
  }, []);

  return (
    <DBContext.Provider value={dbInstance}>
      {children}
    </DBContext.Provider>
  );
}; 