import React from 'react';
import { createRoot } from 'react-dom/client';
import './styles/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/App.scss';
import './styles/onboarding.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { DBProvider } from './providers/DBProvider';
import ReactGA from "react-ga4";
import { AuthProvider } from './providers/AuthProvider';
import { BrowserRouter } from 'react-router-dom';
import { OnboardingProvider } from './providers/OnboardingProvider';
import { Toaster } from 'react-hot-toast';

// Add error boundary wrapper component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      hasError: false,
      errorMessage: '',
      errorStack: ''
    };
  }

  static getDerivedStateFromError(error) {
    return { 
      hasError: true,
      errorMessage: error.toString()
    };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error:', error, errorInfo);
    this.setState({
      errorStack: errorInfo.componentStack
    });
    
    ReactGA.event({
      category: 'Error',
      action: 'App Crash',
      label: error.toString()
    });
    
    // Save error info to localStorage for debugging
    if (typeof localStorage !== 'undefined') {
      try {
        localStorage.setItem('app_last_error', JSON.stringify({
          message: error.toString(),
          stack: errorInfo.componentStack,
          time: new Date().toISOString()
        }));
      } catch (e) {
        console.error('Failed to save error to localStorage:', e);
      }
    }
  }

  handleReload = () => {
    window.location.reload();
  }
  
  handleClearDataAndReload = () => {
    // Clear any database locks
    if (typeof localStorage !== 'undefined') {
      localStorage.removeItem('app_db_initializing');
      localStorage.removeItem('app_db_init_time');
      localStorage.removeItem('app_db_init_by');
      localStorage.setItem('app_db_close_request', Date.now().toString());
    }
    
    // Reload after a short delay
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-boundary-container">
          <div className="error-content">
            <h1>Something went wrong</h1>
            <p className="error-message">{this.state.errorMessage}</p>
            <div className="error-actions">
              <button 
                className="btn btn-primary" 
                onClick={this.handleReload}
              >
                <i className="bi bi-arrow-clockwise me-2"></i>
                Reload Application
              </button>
              <button 
                className="btn btn-outline-danger" 
                onClick={this.handleClearDataAndReload}
              >
                <i className="bi bi-trash me-2"></i>
                Clear Locks &amp; Reload
              </button>
            </div>
            <details className="error-details mt-4">
              <summary>Error Details</summary>
              <pre className="error-stack">{this.state.errorStack}</pre>
            </details>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

// Apply dark mode on initial load
const isDarkMode = localStorage.getItem('darkMode') === 'true';
if (isDarkMode) {
  document.documentElement.setAttribute('data-bs-theme', 'dark');
} else {
  document.documentElement.setAttribute('data-bs-theme', 'light');
}

// Initialize GA4
try {
  ReactGA.initialize("G-FTV0Q2D1H7");
} catch (error) {
  console.warn("Google Analytics could not be initialized. This may be due to an ad blocker or missing permissions.");
  // Create a mock to prevent errors
  window.ReactGA = {
    event: () => {},
    pageview: () => {}
  };
}

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <BrowserRouter>
        <AuthProvider>
          <DBProvider>
            <OnboardingProvider>
              <App />
              <Toaster />
            </OnboardingProvider>
          </DBProvider>
        </AuthProvider>
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>
);

reportWebVitals();

