import React, { useState, useEffect } from 'react';
import { DEFAULT_PROFILE, VALID_PROFILE_FIELDS } from '../../config/defaultProfile';
import WelcomeStep from './steps/WelcomeStep';
import BasicInfoStep from './steps/BasicInfoStep';
import HealthGoalsStep from './steps/HealthGoalsStep';
import DietaryPreferencesStep from './steps/DietaryPreferencesStep';
import ReviewStep from './steps/ReviewStep';
import { toast } from 'react-hot-toast';

const OnboardingFlow = ({ onComplete, user }) => {
  const [step, setStep] = useState(0);
  const [profile, setProfile] = useState(DEFAULT_PROFILE);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  // Reset error when step changes
  useEffect(() => {
    setError(null);
  }, [step]);

  // Ensure user info is properly set
  useEffect(() => {
    if (user && user.id) {
      setProfile(prev => ({
        ...prev,
        user_id: user.id,
        email: user.email || ''
      }));
    }
  }, [user]);

  const steps = [
    {
      component: WelcomeStep,
      title: "Welcome to CalChef"
    },
    {
      component: BasicInfoStep,
      title: "Basic Information"
    },
    {
      component: HealthGoalsStep,
      title: "Health Goals"
    },
    {
      component: DietaryPreferencesStep,
      title: "Dietary Preferences"
    },
    {
      component: ReviewStep,
      title: "Review"
    }
  ];

  const CurrentStep = steps[step].component;

  const handleNext = async (data) => {
    const updatedProfile = { ...profile, ...data };
    setProfile(updatedProfile);
    
    if (step < steps.length - 1) {
      setStep(prev => prev + 1);
    } else {
      setIsSubmitting(true);
      setError(null);
      
      try {
        // Ensure name is required and not empty
        if (!updatedProfile.name || updatedProfile.name.trim() === '') {
          throw new Error('Name is required to create your profile');
        }
        
        // Ensure user_id is set
        if (!updatedProfile.user_id && user) {
          updatedProfile.user_id = user.id;
        }
        
        if (!updatedProfile.user_id) {
          throw new Error('User ID is missing. Please try logging in again.');
        }
        
        // Create a valid profile object with only the allowed fields
        const validProfileData = {
          ...VALID_PROFILE_FIELDS.reduce((acc, field) => {
            if (updatedProfile[field] !== undefined) {
              acc[field] = updatedProfile[field];
            }
            return acc;
          }, {}),
          user_id: updatedProfile.user_id,
          name: updatedProfile.name, // Explicitly include name
          // Don't include type field as it's not in the Supabase schema
        };
        
        console.log('Submitting profile:', validProfileData);
        
        // Submit the profile data
        await onComplete(validProfileData);
        
        // Store completion in local storage for redundancy
        localStorage.setItem(`onboarding_complete_${user.id}`, 'true');
        
        toast.success('Profile setup completed!');
      } catch (err) {
        console.error('Error completing onboarding:', err);
        setError(err.message || 'Failed to complete profile setup. Please try again.');
        toast.error(err.message || 'Failed to complete profile setup. Please try again.');
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleBack = () => {
    setStep(prev => prev - 1);
  };

  const progressPercentage = ((step + 1) / steps.length) * 100;

  return (
    <div className="onboarding-container">
      <div className="progress mb-4">
        <div 
          className="progress-bar" 
          role="progressbar" 
          style={{ width: `${progressPercentage}%` }}
          aria-valuenow={progressPercentage} 
          aria-valuemin="0" 
          aria-valuemax="100"
        />
      </div>
      
      <h2 className="text-center mb-4">{steps[step].title}</h2>
      
      {error && (
        <div className="alert alert-danger mb-4" role="alert">
          {error}
        </div>
      )}
      
      <CurrentStep 
        data={profile}
        onNext={handleNext}
        onBack={handleBack}
        isFirstStep={step === 0}
        isLastStep={step === steps.length - 1}
        isSubmitting={isSubmitting}
      />
    </div>
  );
};

export default OnboardingFlow; 