export default class SimpleIndexedDB {
  constructor(dbName, storeName) {
    this.dbName = dbName
    this.storeName = storeName
    this.db = null
    this.connectionQueue = []
    this.isProcessingQueue = false
    
    // Detect page unload to properly close the database
    if (typeof window !== 'undefined') {
      window.addEventListener('beforeunload', () => {
        this.closeDB()
      })
      
      // Register this instance for global cleanup
      if (typeof window.registerDBInstance === 'function') {
        window.registerDBInstance(this)
      }
    }
  }

  // Close the database connection
  closeDB() {
    try {
      if (this.db) {
        console.log(`Closing database: ${this.dbName}`)
        this.db.close()
        this.db = null
      }
    } catch (error) {
      console.error('Error closing database:', error)
    }
  }

  // Delete and recreate the database when it's in a bad state
  async resetDatabase() {
    return new Promise((resolve, reject) => {
      try {
        // First close any existing connection
        this.closeDB();
        
        console.log(`Attempting to delete database: ${this.dbName}`);
        const deleteRequest = indexedDB.deleteDatabase(this.dbName);
        
        deleteRequest.onsuccess = () => {
          console.log(`Database ${this.dbName} successfully deleted`);
          // Open a new database with the store
          this.openDB(1).then(db => {
            console.log(`Database ${this.dbName} recreated successfully`);
            resolve(db);
          }).catch(error => {
            console.error('Error recreating database after reset:', error);
            reject(error);
          });
        };
        
        deleteRequest.onerror = (event) => {
          console.error('Error deleting database:', event.target.error);
          reject(new Error(`Failed to delete database: ${event.target.error?.message || 'Unknown error'}`));
        };
        
        deleteRequest.onblocked = () => {
          console.warn('Database deletion blocked. Possible open connections.');
          // Try to notify other tabs to close connections
          if (typeof localStorage !== 'undefined') {
            localStorage.setItem('app_db_close_request', Date.now().toString());
            setTimeout(() => {
              this.resetDatabase().then(resolve).catch(reject);
            }, 1000);
          } else {
            reject(new Error('Database deletion blocked. Please close other tabs and try again.'));
          }
        };
      } catch (error) {
        console.error('Error resetting database:', error);
        reject(error);
      }
    });
  }

  async getCurrentVersion() {
    return new Promise((resolve) => {
      try {
        const request = indexedDB.open(this.dbName)
        request.onsuccess = (event) => {
          try {
            const db = event.target.result
            const version = db.version
            db.close()
            resolve(version)
          } catch (err) {
            console.warn('Error in getCurrentVersion success handler:', err)
            resolve(1)
          }
        }
        request.onerror = (event) => {
          console.warn('Error getting database version:', event.target.error)
          resolve(1)
        }
      } catch (err) {
        console.warn('Error in getCurrentVersion:', err)
        resolve(1)
      }
    })
  }

  /**
   * Opens the database. If the database or object store does not exist, it will be created.
   * @param {number} [version=null] - The version of the database (used to upgrade).
   * @returns {Promise} - Resolves with the opened database instance.
   */
  async openDB(version = null) {
    try {
      // Check if the database is already open
      if (this.db) {
        console.log('Database already open, reusing connection');
        return this.db;
      }
      
      console.log(`Opening database: ${this.dbName}`);
      
      // Add a timeout for the entire operation
      const OPEN_TIMEOUT = 10000; // 10 seconds
      const timeoutPromise = new Promise((_, reject) => {
        setTimeout(() => reject(new Error('Database open timed out')), OPEN_TIMEOUT);
      });
      
      // If no version specified, get the current version or use a higher one
      if (!version) {
        try {
          version = await this.getCurrentVersion();
        } catch (err) {
          console.warn('Failed to get current version, using default', err);
          version = 1;
        }
      }

      const openPromise = new Promise((resolve, reject) => {
        let request;
        
        try {
          request = indexedDB.open(this.dbName, version);
        } catch (error) {
          console.error('Failed to open database:', error);
          reject(new Error(`Failed to open the database: ${error.message}`));
          return;
        }

        // Set a timeout for the individual request
        const requestTimeout = setTimeout(() => {
          console.warn('IDBOpenDBRequest timed out, may be blocked');
          reject(new Error('Database open request timed out'));
        }, 5000);

        request.onerror = (event) => {
          clearTimeout(requestTimeout);
          // Handle known error types
          const error = event.target.error;
          console.error('Database error:', error);
          
          if (error.name === 'VersionError') {
            // Version error can happen if another tab is using a higher version
            console.warn('Version error, trying again with current version');
            this.getCurrentVersion().then(currentVersion => {
              this.openDB(currentVersion).then(resolve).catch(reject);
            }).catch(err => {
              reject(new Error(`Failed to handle version error: ${err.message}`));
            });
            return;
          }
          
          if (error.name === 'AbortError') {
            // Connection was aborted, likely due to another connection in another tab
            console.warn('Connection aborted, trying again after delay');
            setTimeout(() => {
              this.openDB(version).then(resolve).catch(reject);
            }, 1000);
            return;
          }
          
          reject(new Error(`Failed to open the database: ${error.message}`));
        };

        request.onblocked = (event) => {
          clearTimeout(requestTimeout);
          console.warn('Database open blocked - another connection might be open');
          
          // Try to automatically close other connections by notifying other tabs
          if (typeof localStorage !== 'undefined') {
            localStorage.setItem('app_db_close_request', Date.now().toString());
            
            // Try again after a short delay
            setTimeout(() => {
              this.openDB(version).then(resolve).catch(reject);
            }, 1000);
          } else {
            reject(new Error('Database connection blocked. Please close other tabs of this application and try again.'));
          }
        };

        request.onupgradeneeded = (event) => {
          try {
            console.log(`Upgrading database to version ${version}`);
            const db = event.target.result;
            
            // Check if store exists before creating
            if (!db.objectStoreNames.contains(this.storeName)) {
              console.log(`Creating store: ${this.storeName}`);
              db.createObjectStore(this.storeName, {
                keyPath: 'id',
                autoIncrement: false,
              });
            }
          } catch (error) {
            console.error('Error during database upgrade:', error);
            clearTimeout(requestTimeout);
            reject(new Error(`Failed to upgrade database: ${error.message}`));
          }
        };

        request.onsuccess = (event) => {
          clearTimeout(requestTimeout);
          try {
            this.db = event.target.result;
            console.log(`Database opened successfully: ${this.dbName} (version ${this.db.version})`);
            
            // Set up error handler for the database
            this.db.onerror = (event) => {
              console.error('Database error:', event.target.error);
            };
            
            // Listen for close requests from other tabs
            if (typeof window !== 'undefined') {
              window.addEventListener('storage', (event) => {
                if (event.key === 'app_db_close_request' && this.db) {
                  console.log('Received database close request from another tab');
                  this.closeDB();
                }
              });
            }
            
            resolve(this.db);
          } catch (error) {
            console.error('Error in onsuccess handler:', error);
            reject(new Error(`Error setting up database: ${error.message}`));
          }
        };
      });

      // Race the open promise against the timeout
      return await Promise.race([openPromise, timeoutPromise]);
    } catch (error) {
      console.error('Critical database error:', error);
      throw new Error(`Failed to initialize database: ${error.message}`);
    }
  }

  /**
   * Adds a new item to the object store.
   * @param {Object} data - The data object to store.
   * @returns {Promise} - Resolves with the ID of the added item.
   */
  addItem(data) {
    return new Promise((resolve, reject) => {
      if (!this.db) {
        reject(new Error('Database not initialized'));
        return;
      }

      try {
        // Check if the store exists
        if (!this.db.objectStoreNames.contains(this.storeName)) {
          console.warn(`Object store ${this.storeName} not found in addItem, attempting to recreate database`);
          this.resetDatabase()
            .then(async (db) => {
              console.log('Database reset successful from addItem, now adding item');
              // Try to add the item after reset
              try {
                const tx = db.transaction([this.storeName], 'readwrite');
                const store = tx.objectStore(this.storeName);
                const req = store.add(data);
                req.onsuccess = (event) => resolve(event.target.result);
                req.onerror = (event) => reject(new Error(`Failed to add item after reset: ${event.target.error}`));
              } catch (err) {
                reject(new Error(`Failed to add item after database reset: ${err.message}`));
              }
            })
            .catch(error => {
              console.error('Error resetting database from addItem:', error);
              reject(error);
            });
          return;
        }

        const transaction = this.db.transaction([this.storeName], 'readwrite');
        const store = transaction.objectStore(this.storeName);

        const request = store.add(data);

        request.onsuccess = (event) => {
          resolve(event.target.result);
        };

        request.onerror = (event) => {
          reject(new Error(`Failed to add item: ${event.target.error}`));
        };

        transaction.oncomplete = () => {
          console.log('Transaction completed: Item added successfully');
        };

        transaction.onerror = (event) => {
          console.error('Transaction error:', event.target.error);
          if (event.target.error && event.target.error.name === 'NotFoundError') {
            this.resetDatabase()
              .then(() => reject(new Error('Database reset due to error, please retry')))
              .catch(resetError => reject(new Error(`Database reset failed: ${resetError.message}`)));
          } else {
            reject(new Error(`Transaction error: ${event.target.error}`));
          }
        };
      } catch (error) {
        console.error('Critical error in addItem:', error);
        if (error.name === 'NotFoundError') {
          this.resetDatabase()
            .then(() => reject(new Error('Database reset due to error, please retry')))
            .catch(resetError => reject(new Error(`Database reset failed: ${resetError.message}`)));
        } else {
          reject(error);
        }
      }
    });
  }

  /**
   * Retrieves an item from the object store by its ID.
   * @param {number} id - The ID of the item to retrieve.
   * @returns {Promise} - Resolves with the retrieved item.
   */
  getItem(id) {
    return new Promise((resolve, reject) => {
      if (!this.db) {
        reject(new Error('Database not initialized'));
        return;
      }
      
      try {
        // Check if the store exists
        if (!this.db.objectStoreNames.contains(this.storeName)) {
          console.warn(`Object store ${this.storeName} not found in getItem, attempting to recreate database`);
          this.resetDatabase()
            .then(() => console.log('Database reset successful from getItem'))
            .catch(error => console.error('Error resetting database from getItem:', error));
          resolve(null);
          return;
        }
      
        const transaction = this.db.transaction([this.storeName], 'readonly');
        const store = transaction.objectStore(this.storeName);
        const request = store.get(id);

        request.onsuccess = (event) => {
          resolve(event.target.result || null);
        };

        request.onerror = (event) => {
          console.error('Error getting item:', event.target.error);
          resolve(null);
        };
        
        transaction.onerror = (event) => {
          console.error('Transaction error in getItem:', event.target.error);
          resolve(null);
        };
      } catch (error) {
        console.error('Critical error in getItem:', error);
        if (error.name === 'NotFoundError') {
          this.resetDatabase()
            .then(() => console.log('Database reset successful after getItem error'))
            .catch(resetError => console.error('Error resetting database after getItem error:', resetError));
        }
        resolve(null);
      }
    });
  }

  /**
   * Retrieves all items from the object store.
   * @returns {Promise} - Resolves with an array of all items in the store.
   */
  async getAllItems() {
    return new Promise((resolve, reject) => {
      if (!this.db) {
        console.warn('Database not initialized in getAllItems');
        resolve([]);
        return;
      }
      
      try {
        // Check if the store exists first
        if (!this.db.objectStoreNames.contains(this.storeName)) {
          console.warn(`Object store ${this.storeName} not found, attempting to recreate it`);
          
          // Close and reopen the database with a higher version to trigger an upgrade
          this.closeDB();
          
          // Try to fix the database, but don't block returning the result
          this.resetDatabase()
            .then(() => console.log('Database reset successful'))
            .catch(error => console.error('Error resetting database:', error));
          
          // Return empty array since we're recreating the database
          resolve([]);
          return;
        }
        
        const transaction = this.db.transaction([this.storeName], 'readonly');
        const store = transaction.objectStore(this.storeName);

        const request = store.getAll();

        request.onsuccess = () => {
          try {
            const items = request.result || [];
            // Process each item's image
            items.forEach((item) => {
              try {
                // Check for base64 image data from Supabase
                if (item.image_base64) {
                  try {
                    // Make sure the base64 string is valid
                    if (typeof item.image_base64 === 'string' && item.image_base64.includes(',')) {
                      const byteString = atob(item.image_base64.split(',')[1]);
                      const mimeString = item.image_base64.split(',')[0].split(':')[1].split(';')[0];
                      const ab = new ArrayBuffer(byteString.length);
                      const ia = new Uint8Array(ab);
                      
                      for (let i = 0; i < byteString.length; i++) {
                        ia[i] = byteString.charCodeAt(i);
                      }
                      
                      const blob = new Blob([ab], { type: mimeString });
                      item.imageBlob = blob;
                      item.imageURL = URL.createObjectURL(blob);
                    } else {
                      console.warn('Invalid image_base64 format:', typeof item.image_base64);
                    }
                  } catch (imgError) {
                    console.error('Error processing base64 image for item', item.id, imgError);
                  }
                }
                // Handle existing imageBlob
                else if (item.imageBlob) {
                  try {
                    item.imageURL = URL.createObjectURL(item.imageBlob);
                  } catch (blobError) {
                    console.error('Error creating URL from imageBlob', blobError);
                  }
                }
              } catch (itemError) {
                console.error('Error processing item', item.id, itemError);
              }
            });
            resolve(items);
          } catch (processError) {
            console.error('Error processing items:', processError);
            // Return empty array on error
            resolve([]);
          }
        };

        request.onerror = (event) => {
          console.error('Failed to retrieve items:', event.target.error);
          // Return empty array on error
          resolve([]);
        };
        
        transaction.onerror = (event) => {
          // Check specifically for missing object store error
          if (event.target.error && event.target.error.name === 'NotFoundError') {
            console.error('Object store not found in transaction, initiating database reset');
            this.resetDatabase()
              .then(() => console.log('Database reset successful after transaction error'))
              .catch(error => console.error('Error resetting database after transaction error:', error));
          } else {
            console.error('Transaction error in getAllItems:', event.target.error);
          }
          resolve([]);
        };
      } catch (error) {
        console.error('Critical error in getAllItems:', error);
        
        // If it's a missing object store error, reset the database
        if (error.name === 'NotFoundError') {
          this.resetDatabase()
            .then(() => console.log('Database reset successful after critical error'))
            .catch(resetError => console.error('Error resetting database after critical error:', resetError));
        }
        
        resolve([]);
      }
    });
  }

  /**
   * Updates an existing item in the object store.
   * @param {Object} data - The updated data object, which must include an existing ID.
   * @returns {Promise} - Resolves with a success message.
   */
  updateItem(data) {
    return new Promise((resolve, reject) => {
      if (!data.id) {
        reject(new Error('No ID provided for update'));
        return;
      }

      const transaction = this.db.transaction([this.storeName], 'readwrite');
      const store = transaction.objectStore(this.storeName);

      // First, get the existing item
      const getRequest = store.get(data.id);

      getRequest.onsuccess = () => {
        const existingItem = getRequest.result;

        if (!existingItem) {
          reject(new Error(`Item with ID ${data.id} not found`));
          return;
        }

        // Preserve existing properties that shouldn't be overwritten
        const updatedData = {
          ...data,
          syncStatus: data.syncStatus || existingItem.syncStatus,
          imageBlob: data.imageBlob || existingItem.imageBlob
        };

        // If there's an imageBlob, create a new URL
        if (updatedData.imageBlob) {
          updatedData.imageURL = URL.createObjectURL(updatedData.imageBlob);
        }

        // Now update the item
        const updateRequest = store.put(updatedData);

        updateRequest.onsuccess = () => {
          resolve(`Item with ID ${data.id} updated successfully.`);
        };

        updateRequest.onerror = (event) => {
          reject(`Failed to update item: ${event.target.error}`);
        };
      };

      getRequest.onerror = (event) => {
        reject(`Failed to retrieve item for update: ${event.target.error}`);
      };
    });
  }

  /**
   * Deletes an item from the object store by its ID.
   * @param {number} id - The ID of the item to delete.
   * @returns {Promise} - Resolves with a success message.
   */
  deleteItem(id) {
    return new Promise((resolve, reject) => {
      if (id === undefined) {
        reject('No ID provided for deletion')
        return
      }

      const transaction = this.db.transaction([this.storeName], 'readwrite')
      const store = transaction.objectStore(this.storeName)

      const request = store.delete(id)

      request.onsuccess = () => {
        resolve(`Item with ID ${id} deleted successfully.`)
      }

      request.onerror = (event) => {
        reject(`Failed to delete item: ${event.target.error}`)
      }
    })
  }
}

// Ensure database connections are closed when the page is unloaded
if (typeof window !== 'undefined') {
  let dbInstances = [];
  
  // Function to register a DB instance for cleanup
  window.registerDBInstance = (instance) => {
    if (!dbInstances.includes(instance)) {
      dbInstances.push(instance);
    }
  };
  
  // Function to close all database connections
  const closeAllDBs = () => {
    console.log(`Closing ${dbInstances.length} database connections...`);
    dbInstances.forEach(db => {
      try {
        if (db && typeof db.closeDB === 'function') {
          db.closeDB();
        }
      } catch (e) {
        console.error('Error closing database:', e);
      }
    });
    dbInstances = [];
  };
  
  // Register handlers for page unload events
  window.addEventListener('beforeunload', closeAllDBs);
  window.addEventListener('unload', closeAllDBs);
  
  // Also handle page visibility change to clean up when page is hidden
  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'hidden') {
      console.log('Page hidden, releasing database connections...');
      dbInstances.forEach(db => {
        try {
          if (db && typeof db.closeDB === 'function') {
            db.closeDB();
          }
        } catch (e) {
          console.error('Error closing database on visibility change:', e);
        }
      });
    }
  });
}
