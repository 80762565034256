import { supabase } from '../supabaseClient'
import { SyncRecord } from './SyncRecord'

export default class SyncManager {
  constructor(localDB, tableName) {
    this.localDB = localDB
    this.tableName = tableName
    this.syncQueue = []
    this.MAX_BATCH_SIZE = 50
    this.MAX_RETRIES = 3
  }

  async initialize() {
    try {
      // Initialize sync queue from stored items
      const items = await this.localDB.getAllItems()
      this.syncQueue = items
        .filter(item => item.syncStatus === 'pending')
        .map(item => ({
          data: item,
          retryCount: 0
        }))
    } catch (error) {
      console.error('Error initializing SyncManager:', error)
    }
  }

  async queueChange(data) {
    const syncRecord = new SyncRecord({
      ...data,
      syncStatus: 'pending',
      retryCount: 0
    })
    this.syncQueue.push(syncRecord)
    
    // Trigger sync after queueing the change
    await this.performSync()
    
    return syncRecord
  }

  async syncIfNeeded() {
    if (!this.isOnline || this.syncInProgress) return false
    
    const pendingChanges = await this.getPendingChanges()
    if (pendingChanges.length === 0) return false

    return this.performSync()
  }

  async performSync() {
    try {
      const pendingChanges = await this.getPendingChanges()
      
      // Filter out user profile records
      const nonProfileChanges = pendingChanges.filter(record => 
        record.data.type !== 'userProfile'
      );
      
      if (nonProfileChanges.length === 0) {
        console.log('No pending changes, fetching remote changes')
        await this.fetchRemoteChanges()
        return
      }

      console.log('Creating batches for pending changes')
      const batches = this.createBatches(nonProfileChanges)
      
      for (const batch of batches) {
        await this.processBatch(batch)
      }

      await this.fetchRemoteChanges()
    } catch (error) {
      console.error('Sync failed:', error)
      throw error
    }
  }

  createBatches(changes) {
    const batches = []
    for (let i = 0; i < changes.length; i += this.MAX_BATCH_SIZE) {
      batches.push(changes.slice(i, i + this.MAX_BATCH_SIZE))
    }
    return batches
  }

  async processBatch(batch) {
    try {
        const userProfiles = batch.filter(record => record.data.type === 'userProfile');
        const otherRecords = batch.filter(record => record.data.type !== 'userProfile');

        // Handle user profiles
        if (userProfiles.length > 0) {
            for (const profile of userProfiles) {
                const prepared = await this.prepareForSupabase(profile);
                if (prepared) {
                    console.log('Preparing to upsert user profile:', prepared);
                    const { data, error } = await supabase
                        .from('user_profiles')
                        .upsert(prepared)
                        .select();

                    if (error) {
                        console.error('Error syncing user profile:', error);
                        await this.handleSyncError([profile], error);
                    } else {
                        console.log('Successfully synced user profile:', data);
                        await this.updateLocalRecords([profile], [data[0]]);
                    }
                }
            }
        }

        // Separate deletes from other operations
        const deleteRecords = otherRecords.filter(record => record.data.operation === 'delete');
        const remainingRecords = otherRecords.filter(record => record.data.operation !== 'delete');
        
        try {
            // Handle deletes first
            if (deleteRecords.length > 0) {
                const deleteIds = deleteRecords.map(record => record.data.id);
                console.log('Deleting records from Supabase:', deleteIds);
                
                // Delete all records in one operation instead of one by one
                const { error: deleteError } = await supabase
                    .from(this.tableName)
                    .delete()
                    .in('id', deleteIds);
                
                if (deleteError) {
                    console.error('Error deleting records:', deleteError);
                    await this.handleSyncError(deleteRecords, deleteError);
                } else {
                    console.log('Successfully deleted records:', deleteIds);
                    // Remove all deleted items from local DB
                    for (const record of deleteRecords) {
                        await this.localDB.deleteItem(record.data.id);
                        // Remove from sync queue
                        this.syncQueue = this.syncQueue.filter(item => 
                            item.data.id !== record.data.id
                        );
                    }
                }
            }
            
            // Handle other operations (inserts/updates)
            if (remainingRecords.length > 0) {
                const preparedRecords = await Promise.all(
                    remainingRecords.map(async record => {
                        const prepared = await this.prepareForSupabase(record);
                        console.log('Record before preparation:', record);
                        console.log('Record after preparation:', prepared);
                        return prepared;
                    })
                );

                const validRecords = preparedRecords.filter(record => record !== null);

                if (validRecords.length > 0) {
                    const { data, error } = await supabase
                        .from(this.tableName)
                        .upsert(validRecords)
                        .select();
                    
                    if (error) {
                        console.error('Supabase error:', error);
                        await this.handleSyncError(remainingRecords, error);
                    } else {
                        await this.updateLocalRecords(remainingRecords, data);
                    }
                }
            }
            
            // Mark deleted records as synced
            for (const record of deleteRecords) {
                await this.localDB.deleteItem(record.data.id);
            }
            
        } catch (error) {
            console.error('Error processing batch:', error);
            await this.handleSyncError(batch, error);
        }
    } catch (error) {
        console.error('Error processing batch:', error);
        await this.handleSyncError(batch, error);
    }
  }

  async processCreates(records) {
    if (records.length === 0) return

    const { data, error } = await supabase
      .from(this.tableName)
      .insert(records.map(r => this.prepareForSupabase(r)))
      .select()

    if (error) {
      await this.handleSyncError(records, error)
      return
    }

    await this.updateLocalRecords(records, data)
  }

  async handleSyncError(records, error) {
    for (const record of records) {
      if (record.retryCount >= this.MAX_RETRIES) {
        await this.markRecordAsFailed(record, error)
      } else {
        await this.markRecordForRetry(record)
      }
    }
  }

  async prepareForSupabase(record) {
    // If it's a user profile, handle it differently
    if (record.data.type === 'userProfile') {
        const { data: { session } } = await supabase.auth.getSession();
        if (!session?.user?.id) return null;

        // Remove undefined values and ensure proper data structure
        const profileData = {
            user_id: session.user.id,
            sex: record.data.sex || null,
            age: record.data.age || null,
            height: record.data.height || null,
            weight: record.data.weight || null,
            activity_level: record.data.activityLevel || record.data.activity_level || null,
            dietary_requirements: record.data.dietaryRequirements || record.data.dietary_requirements || null,
            allergies: record.data.allergies || null,
            rdi: record.data.rdi || {}
        };

        // Remove any undefined values
        Object.keys(profileData).forEach(key => 
            profileData[key] === undefined && delete profileData[key]
        );

        return profileData;
    }

    // If it's a delete operation, just return the ID
    if (record.data.operation === 'delete') {
        return {
            // Convert UUID string to numeric ID if it's a UUID format
            id: this.convertIdToNumeric(record.data.id)
        };
    }

    // Ensure we're accessing the correct data structure
    const data = record.data.data || record.data;
    
    // Handle image conversion
    let image_base64 = null;
    if (data.imageBlob) {
        image_base64 = await this.blobToBase64(data.imageBlob);
    } else if (data.image_base64) {
        image_base64 = data.image_base64;
    }
    
    // Fetch the user session
    const { data: { session } } = await supabase.auth.getSession();
    if (!session?.user?.id) {
        console.error('No authenticated user, cannot set user_id');
        return null;
    }
    
    // Only include fields that actually have values
    const preparedData = {
        // Convert the ID to numeric format if it's a UUID string
        id: this.convertIdToNumeric(data.id),
        name: data.name,
        short_name: data.shortName || data.short_name,
        description: data.description,
        calories: data.calories,
        serving_size: data.servingSize || data.serving_size,
        confidence_score: data.confidenceScore || data.confidence_score,
        macronutrients: data.macronutrients || {}, // Ensure macronutrients is at least an empty object
        cholesterol: data.cholesterol,
        sodium: data.sodium,
        exercise_equivalent: data.exerciseEquivalent || data.exercise_equivalent,
        allergy_warning: data.allergyWarning || data.allergy_warning,
        upload_date_time: data.uploadDateTime || data.upload_date_time || new Date().toISOString(),
        meal_category: data.mealCategory || data.meal_category,
        type: data.type,
        recipe: data.recipe || null,
        version: data.version || 1,
        image_base64: image_base64,
        user_id: session.user.id
    };

    // Remove any undefined values but keep null values for image_base64
    Object.keys(preparedData).forEach(key => 
        preparedData[key] === undefined && delete preparedData[key]
    );

    return preparedData;
  }

  /**
   * Converts UUID strings to numeric IDs for compatibility with Supabase
   * @param {string|number} id - The ID to convert
   * @returns {number} - A numeric ID
   */
  convertIdToNumeric(id) {
    // If id is already a number, return it
    if (typeof id === 'number') {
      return id;
    }
    
    // If id is a string that looks like a UUID (contains hyphens or letters)
    if (typeof id === 'string' && (id.includes('-') || /[a-zA-Z]/.test(id))) {
      // Use a timestamp-based ID
      return Date.now();
    }
    
    // If id is a string of digits, convert to number
    if (typeof id === 'string' && /^\d+$/.test(id)) {
      return parseInt(id, 10);
    }
    
    // Fallback: generate a new timestamp-based ID
    return Date.now();
  }

  async updateLocalRecords(localRecords, remoteRecords) {
    for (let i = 0; i < localRecords.length; i++) {
      const localRecord = localRecords[i]
      const remoteRecord = remoteRecords[i]
      
      if (!remoteRecord) {
        console.warn(`No remote record found for local record ${localRecord.id}`)
        continue
      }

      await this.localDB.updateItem({
        ...remoteRecord,
        syncStatus: 'synced',
        version: localRecord.version
      })
    }
  }

  async fetchRemoteChanges() {
    try {
      const lastSync = await this.getLastSyncTime()
      
      const { data, error } = await supabase
        .from(this.tableName)
        .select('*')
        .gt('upload_date_time', lastSync)
        
      if (error) throw error
      if (!data || data.length === 0) return

      console.log('Remote changes fetched:', data)
      for (const record of data) {
        await this.mergeRemoteChange(record)
      }

      await this.updateLastSyncTime()
    } catch (error) {
      console.error('Error fetching remote changes:', error)
    }
  }

  async mergeRemoteChange(remoteRecord) {
    try {
      const localRecord = await this.localDB.getItem(remoteRecord.id)
      
      if (!localRecord) {
        // If local record doesn't exist, create it
        console.log('Creating new local record from remote:', remoteRecord)
        await this.localDB.addItem({
          ...remoteRecord,
          syncStatus: 'synced'
        })
        return
      }

      if (localRecord.version < remoteRecord.version) {
        console.log('Updating local record with remote changes:', remoteRecord)
        await this.localDB.updateItem({
          ...remoteRecord,
          syncStatus: 'synced'
        })
      }
    } catch (error) {
      console.error('Error merging remote change:', error, 'Remote record:', remoteRecord)
    }
  }

  async getPendingChanges() {
    try {
      const items = await this.localDB.getAllItems();
      return items
        .filter(item => item.syncStatus === 'pending')
        .map(item => new SyncRecord({
          id: item.id,
          version: item.version || 1,
          lastModified: item.uploadDateTime || item.upload_date_time || new Date().toISOString(),
          syncStatus: 'pending',
          operation: item.operation,
          retryCount: item.retryCount || 0,
          data: item
        }));
    } catch (error) {
      console.error('Error getting pending changes:', error);
      return [];
    }
  }

  async getLastSyncTime() {
    try {
      const items = await this.localDB.getAllItems()
      const syncMetadata = items.find(item => item.type === 'syncMetadata')
      return syncMetadata?.lastSyncTime || new Date(0).toISOString()
    } catch (error) {
      console.error('Error getting last sync time:', error)
      return new Date(0).toISOString()
    }
  }

  async updateLastSyncTime() {
    const now = new Date().toISOString()
    const items = await this.localDB.getAllItems()
    const syncMetadata = items.find(item => item.type === 'syncMetadata')
    
    if (syncMetadata) {
      await this.localDB.updateItem({
        ...syncMetadata,
        lastSyncTime: now
      })
    } else {
      await this.localDB.addItem({
        type: 'syncMetadata',
        lastSyncTime: now
      })
    }
  }

  async markRecordForRetry(record) {
    if (!record.data || !record.data.id) {
      console.error('Invalid record for retry:', record)
      return
    }
    
    const updatedRecord = {
      ...record.data,
      id: record.data.id,
      retryCount: (record.retryCount || 0) + 1,
      syncStatus: 'pending'
    }
    await this.localDB.updateItem(updatedRecord)
  }

  async markRecordAsFailed(record, error) {
    const updatedRecord = {
      ...record.data,
      syncStatus: 'failed',
      syncError: error.message
    }
    await this.localDB.updateItem(updatedRecord)
  }

  async blobToBase64(blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
  }

  async clearSyncQueue(ids) {
    this.syncQueue = this.syncQueue.filter(item => !ids.includes(item.data.id));
  }
} 