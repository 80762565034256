import React, { createContext, useContext, useState, useEffect } from 'react'
import { supabase } from '../supabaseClient'

const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)
  const [previousUserId, setPreviousUserId] = useState(null)

  useEffect(() => {
    // Initialize the auth state and listen for changes
    setLoading(true)

    // Get the current session
    const initUser = async () => {
      const { data: { session } } = await supabase.auth.getSession()
      setUser(session?.user || null)
      if (session?.user) {
        setPreviousUserId(session.user.id)
      }
      setLoading(false)
    }

    initUser()

    // Listen for auth changes
    const { data: { subscription } } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        console.log('Auth state changed:', event)
        
        if (event === 'SIGNED_IN') {
          console.log('User signed in:', session.user)
          setUser(session.user)
          setPreviousUserId(session.user.id)
        } else if (event === 'SIGNED_OUT') {
          console.log('User signed out')
          // Clear local user data when signed out
          if (previousUserId) {
            clearLocalUserData(previousUserId)
          }
          setUser(null)
          setPreviousUserId(null)
        } else if (event === 'USER_UPDATED') {
          console.log('User updated:', session.user)
          setUser(session.user)
        } else if (event === 'USER_DELETED') {
          console.log('User deleted')
          if (previousUserId) {
            clearLocalUserData(previousUserId)
          }
          setUser(null)
          setPreviousUserId(null)
        } else if (session?.user) {
          // Session exists but it's a different user
          if (previousUserId && previousUserId !== session.user.id) {
            console.log('Different user detected, clearing previous user data')
            clearLocalUserData(previousUserId)
          }
          setUser(session.user)
          setPreviousUserId(session.user.id)
        }

        setLoading(false)
      }
    )

    return () => {
      subscription.unsubscribe()
    }
  }, [previousUserId])

  // Function to clear user data from localStorage and notify other components
  const clearLocalUserData = (userId) => {
    console.log('Clearing local data for user:', userId)
    
    // Clear onboarding completion status
    if (userId) {
      localStorage.removeItem(`onboarding_complete_${userId}`)
    }
    
    // Use localStorage to notify other tabs about the user change
    localStorage.setItem('app_user_changed', Date.now().toString())
    
    // If we have access to BroadcastChannel, use it
    if (typeof BroadcastChannel !== 'undefined') {
      try {
        const channel = new BroadcastChannel('app_sync_channel')
        channel.postMessage({ 
          type: 'CLEAR_USER_DATA', 
          data: { userId } 
        })
        channel.close()
      } catch (error) {
        console.error('Failed to broadcast user change:', error)
      }
    }
    
    // Dispatch an event for local components to refresh
    const userChangeEvent = new Event('app_user_changed')
    window.dispatchEvent(userChangeEvent)
  }

  const value = {
    user,
    loading,
    signOut: () => supabase.auth.signOut()
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(AuthContext)
} 