import React, { useState, useEffect } from 'react';
import { useDB } from '../providers/DBProvider';
import { Tooltip } from 'bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useAuth } from '../providers/AuthProvider';
import { supabase } from '../supabaseClient';
import { toast } from 'react-hot-toast';

const UserProfile = ({ onProfileUpdate, userProfile }) => {
  const [name, setName] = useState('');
  const [sex, setSex] = useState('');
  const [age, setAge] = useState('');
  const [height, setHeight] = useState('');
  const [weight, setWeight] = useState('');
  const [activityLevel, setActivityLevel] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [dietaryRequirements, setDietaryRequirements] = useState('');
  const [isDarkMode, setIsDarkMode] = useState(document.documentElement.getAttribute('data-bs-theme') === 'dark');
  const [allergies, setAllergies] = useState('');
  const [rdi, setRdi] = useState(userProfile?.rdi || {});

  const { user } = useAuth();
  const db = useDB();

  const activityLevels = [
    { value: "sedentary", label: "Sedentary", description: "Little or no exercise" },
    { value: "lightly active", label: "Lightly Active", description: "Light exercise 1-3 days/week" },
    { value: "moderately active", label: "Moderately Active", description: "Moderate exercise 3-5 days/week" },
    { value: "very active", label: "Very Active", description: "Hard exercise 6-7 days a week" },
    { value: "super active", label: "Super Active", description: "Very hard exercise & physical job or 2x training" },
  ];

  useEffect(() => {
    if (userProfile) {
      console.log('Setting profile state from props:', userProfile);
      setName(userProfile.name || '');
      setSex(userProfile.sex || '');
      setAge(userProfile.age ? userProfile.age.toString() : '');
      setHeight(userProfile.height ? userProfile.height.toString() : '');
      setWeight(userProfile.weight ? userProfile.weight.toString() : '');
      setActivityLevel(userProfile.activity_level || '');
      setDietaryRequirements(userProfile.dietary_requirements || '');
      setAllergies(userProfile.allergies || '');
      setRdi(userProfile.rdi || {});
    } else if (user) {
      loadUserProfile();
    }
  }, [userProfile, user]);

  const loadUserProfile = async () => {
    try {
      const { data, error } = await supabase
        .from('user_profiles')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;

      if (data) {
        setName(data.name || '');
        setSex(data.sex || '');
        setAge(data.age || '');
        setHeight(data.height || '');
        setWeight(data.weight || '');
        setActivityLevel(data.activity_level || '');
        setDietaryRequirements(data.dietary_requirements || '');
        setAllergies(data.allergies || '');
        setRdi(data.rdi || {});
      }
    } catch (error) {
      console.error('Error loading user profile:', error);
    }
  };

  useEffect(() => {
    // Initialize tooltips
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
  }, [isEditing]); // Re-initialize when isEditing changes

  useEffect(() => {
    const savedDarkMode = localStorage.getItem('darkMode') === 'true';
    setIsDarkMode(savedDarkMode);
    document.documentElement.setAttribute('data-bs-theme', savedDarkMode ? 'dark' : 'light');
  }, []);

  const calculateRDI = (profile) => {
    // Calculate BMR
    let bmr;
    if (profile.sex === 'male') {
      bmr = (10 * profile.weight) + (6.25 * profile.height) - (5 * profile.age) + 5;
    } else {
      bmr = (10 * profile.weight) + (6.25 * profile.height) - (5 * profile.age) - 161;
    }

    // Calculate TDEE
    let tdee;
    switch (profile.activityLevel) {
      case 'sedentary':
        tdee = bmr * 1.2;
        break;
      case 'lightly active':
        tdee = bmr * 1.375;
        break;
      case 'moderately active':
        tdee = bmr * 1.55;
        break;
      case 'very active':
        tdee = bmr * 1.725;
        break;
      case 'super active':
        tdee = bmr * 1.9;
        break;
      default:
        tdee = bmr * 1.2;
    }

    // Calculate macronutrient ratios (example: 50% carbs, 30% protein, 20% fat)
    const calculatedRDI = {
      calories: Math.round(tdee),
      carbohydrates: Math.round((tdee * 0.5) / 4), // 4 calories per gram of carbs
      protein: Math.round((tdee * 0.3) / 4), // 4 calories per gram of protein
      fats: Math.round((tdee * 0.2) / 9), // 9 calories per gram of fat
      fiber: 30, // General recommendation
      sugar: Math.round((tdee * 0.10) / 4), // 10% of total calories
      cholesterol: 300, // mg, general recommendation
      sodium: Math.round(profile.weight * 23), // 23 mg per kg of body weight
    };

    return calculatedRDI;
  };

  const handleRDIChange = (nutrient, value) => {
    setRdi(prevRdi => ({
      ...prevRdi,
      [nutrient]: parseInt(value) || 0
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!user) {
        console.error('No authenticated user');
        return;
    }

    // Ensure name is not empty
    if (!name.trim()) {
        alert('Please enter your name');
        return;
    }

    try {
        // Prepare profile data with proper type and validation
        const profileData = {
            user_id: user.id,
            name,
            sex,
            age: age ? parseInt(age) : null,
            height: height ? parseFloat(height) : null,
            weight: weight ? parseFloat(weight) : null,
            activity_level: activityLevel,
            dietary_requirements: dietaryRequirements,
            allergies,
            rdi: calculateRDI({
                sex,
                age: parseInt(age) || 0,
                height: parseFloat(height) || 0,
                weight: parseFloat(weight) || 0,
                activityLevel
            })
        };

        console.log('Updating profile with data:', profileData);
        
        if (onProfileUpdate) {
            await onProfileUpdate(profileData);
            toast.success('Profile updated successfully!');
        } else {
            console.error('No onProfileUpdate function provided');
            toast.error('Could not update profile due to a configuration error.');
        }
        setIsEditing(false);
    } catch (error) {
        console.error('Error updating profile:', error);
        toast.error(`Failed to update profile: ${error.message || 'Unknown error'}`);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    if (userProfile) {
      setName(userProfile.name || '');
      setSex(userProfile.sex || '');
      setAge(userProfile.age || '');
      setHeight(userProfile.height || '');
      setWeight(userProfile.weight || '');
      setActivityLevel(userProfile.activityLevel || '');
      setDietaryRequirements(userProfile.dietaryRequirements || '');
      setAllergies(userProfile.allergies || '');
    }
  };

  const toggleDarkMode = (e) => {
    const newDarkModeState = e.target.checked;
    setIsDarkMode(newDarkModeState);
    
    // Update DOM
    document.documentElement.setAttribute('data-bs-theme', newDarkModeState ? 'dark' : 'light');
    
    // Update localStorage
    localStorage.setItem('darkMode', newDarkModeState.toString());
    
    // Dispatch event for other components
    window.dispatchEvent(new CustomEvent('darkModeToggle', { 
      detail: newDarkModeState 
    }));
  };

  const handleLogout = async () => {
    try {
      // Notify other components and tabs that user is logging out
      if (typeof localStorage !== 'undefined') {
        // Set a flag to indicate logout is happening
        localStorage.setItem('app_logout_in_progress', 'true');
        
        // Clear any user-specific data from localStorage
        localStorage.removeItem(`onboarding_complete_${user.id}`);
      }
      
      // Sign out from Supabase
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      
      // Clear the localStorage flag
      localStorage.removeItem('app_logout_in_progress');
      
      // Reload the page to ensure clean state
      window.location.href = '/';
    } catch (error) {
      console.error('Error logging out:', error);
      localStorage.removeItem('app_logout_in_progress');
    }
  };

  if (!user) {
    return (
      <div className="user-profile-container">
        <p>Please sign in to manage your profile.</p>
      </div>
    );
  }

  return (
    <div className="user-profile-container">
      <div className="profile-header d-flex justify-content-between align-items-center mb-4">
        <h2 className="profile-title">
          <i className="bi bi-person-circle me-2"></i>Profile Settings
        </h2>
        <div className="theme-toggle">
          <input
            className="theme-toggle-input"
            type="checkbox"
            id="darkModeToggle"
            checked={isDarkMode}
            onChange={toggleDarkMode}
          />
          <label className="theme-toggle-label" htmlFor="darkModeToggle">
            <i className="bi bi-sun-fill sun-icon"></i>
            <i className="bi bi-moon-fill moon-icon"></i>
            <span className="toggle-ball"></span>
          </label>
        </div>
      </div>

      {!isEditing && userProfile ? (
        <div className="profile-view">
          <div className="profile-stats">
            {[
              { label: 'Name', value: name, icon: 'person' },
              { label: 'Sex', value: sex, icon: 'gender-ambiguous' },
              { label: 'Age', value: age, icon: 'calendar3' },
              { label: 'Height', value: height ? `${height} cm` : '', icon: 'rulers' },
              { label: 'Weight', value: weight ? `${weight} kg` : '', icon: 'clipboard-data' },
              { label: 'Activity Level', value: activityLevel, icon: 'activity' },
              { label: 'Dietary Requirements', value: dietaryRequirements || 'None', icon: 'egg-fried' },
              { label: 'Allergies', value: allergies || 'None', icon: 'exclamation-triangle' }
            ].map((item, index) => (
              <div key={index} className="stat-card">
                <div className="stat-icon">
                  <i className={`bi bi-${item.icon}`}></i>
                </div>
                <div className="stat-content">
                  <span className="stat-label">{item.label}</span>
                  <span className="stat-value">{item.value}</span>
                </div>
              </div>
            ))}
          </div>
          <div className="profile-actions mt-4">
            <button className="btn btn-primary w-100 mb-3" onClick={() => setIsEditing(true)}>
              <i className="bi bi-pencil-square me-2"></i>Edit Profile
            </button>
            <button 
              className="btn btn-outline-danger w-100 logout-button" 
              onClick={handleLogout}
            >
              <i className="bi bi-box-arrow-right me-2"></i>
              Sign Out
            </button>
          </div>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="name" className="form-label">
              Name
            </label>
            <input
              type="text"
              id="name"
              className="form-control"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="sex" className="form-label">
              Sex
              <i className="bi bi-info-circle ms-2" data-bs-toggle="tooltip" data-bs-placement="top" title="RDIs are calculated based on biological sex for accuracy."></i>
            </label>
            <select id="sex" className="form-select" value={sex} onChange={(e) => setSex(e.target.value)} required>
              <option value="">Select sex</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="age" className="form-label">Age</label>
            <input type="number" id="age" className="form-control" value={age} onChange={(e) => setAge(e.target.value)} required />
          </div>
          <div className="mb-3">
            <label htmlFor="height" className="form-label">Height (cm)</label>
            <input type="number" id="height" className="form-control" value={height} onChange={(e) => setHeight(e.target.value)} required />
          </div>
          <div className="mb-3">
            <label htmlFor="weight" className="form-label">Weight (kg)</label>
            <input type="number" id="weight" className="form-control" value={weight} onChange={(e) => setWeight(e.target.value)} required />
          </div>
          <div className="mb-3">
            <label htmlFor="activityLevel" className="form-label">Activity Level</label>
            <select
              id="activityLevel"
              className="form-select"
              value={activityLevel}
              onChange={(e) => setActivityLevel(e.target.value)}
              required
            >
              <option value="">Select activity level</option>
              {activityLevels.map((level) => (
                <option key={level.value} value={level.value}>
                  {level.label}
                </option>
              ))}
            </select>
            {activityLevel && (
              <small className="form-text text-muted">
                - {activityLevels.find(level => level.value === activityLevel)?.description}
              </small>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="dietaryRequirements" className="form-label">
              Dietary Requirements
              <i className="bi bi-info-circle ms-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Adding preferences here will help generate recipes that better suit your requirements."></i>
            </label>
            <input
              type="text"
              id="dietaryRequirements"
              className="form-control"
              value={dietaryRequirements}
              onChange={(e) => setDietaryRequirements(e.target.value)}
              placeholder="Leave blank or add 'Vegan', 'Gluten-free', etc."
            />
          </div>
          <div className="mb-3">
            <label htmlFor="allergies" className="form-label">
              Allergies
              <i className="bi bi-info-circle ms-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Listing allergies here will trigger alerts when you upload images of food that potentially contain these allergens. But be aware that this is intended to be used as a guide and is not garenteed to be 100% accurate."></i>
            </label>
            <input
              type="text"
              id="allergies"
              className="form-control"
              value={allergies}
              onChange={(e) => setAllergies(e.target.value)}
              placeholder="Enter allergies (e.g., peanuts, shellfish)"
            />
          </div>
          <div className="d-flex justify-content-between">
            <button type="submit" className="btn btn-primary">
              {userProfile ? 'Update Profile' : 'Create Profile'}
            </button>
            {userProfile && (
              <button 
                type="button" 
                className="btn btn-outline-secondary" 
                onClick={handleCancel}
              >
                Cancel
              </button>
            )}
          </div>
        </form>
      )}
    </div>
  );
};

export default UserProfile;
