import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useDB } from './providers/DBProvider'
import UploadImage from './components/UploadImage'
import ConsumedFood from './ConsumedFood'
import UserProfile from './components/UserProfile'
import NutritionSummary from './components/NutritionSummary'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import SavedRecipes from './components/SavedRecipes'
import WeeklyNutritionSummary from './components/WeeklyNutritionSummary'
import MicronutrientsSummary from './components/MicronutrientsSummary'
import ConsumptionHistoryItem from './components/ConsumptionHistoryItem'
import { useSwipeable } from 'react-swipeable'
import { categorizeMealTime } from './services/analyzeImage'
import ReactGA from "react-ga4"
import Purge from './purge'
import Auth from './components/Auth';
import { useAuth } from './providers/AuthProvider';
import { createClient } from '@supabase/supabase-js'
import { supabase } from './supabaseClient';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import AuthCallback from './pages/AuthCallback';
import OnboardingFlow from './components/Onboarding/OnboardingFlow';
import { toast } from 'react-hot-toast';
import { TrialManager } from './services/TrialManager';

function App() {
  const { user } = useAuth();
  const [uploading, setUploading] = useState(false)
  const [responseList, setResponseList] = useState([])
  const [dbError, setdbError] = useState(null)
  const [userProfile, setUserProfile] = useState(null)
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [activeTab, setActiveTab] = useState(() => {
    // Check if the screen width is less than 768px (typical mobile breakpoint)
    if (typeof window !== 'undefined' && window.innerWidth < 768) {
      return 'history';
    }
    return 'upload';
  });
  const [activeNutritionSubTab, setActiveNutritionSubTab] = useState('daily')
  const [refreshSavedRecipesTrigger, setRefreshSavedRecipesTrigger] = useState(0)
  const [currentWeekOffset, setCurrentWeekOffset] = useState(0)
  const [selectedWeekDate, setSelectedWeekDate] = useState(new Date())
  const [allergyAlert, setAllergyAlert] = useState(null)
  const db = useDB()
  const [mostRecentItem, setMostRecentItem] = useState(null)
  const [refreshTrigger, setRefreshTrigger] = useState(0)
  // const [purgeTriggered, setPurgeTriggered] = useState(0);
  const [isCalorieCardExpanded, setIsCalorieCardExpanded] = useState(false);
  const [weeklyConsumptionItems, setWeeklyConsumptionItems] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const drawerRef = useRef(null);
  const [isRecentItemExpanded, setIsRecentItemExpanded] = useState(false);

  const leftNavRef = useRef(null);
  const rightNavRef = useRef(null);

  const [isWeeklyDataLoading, setIsWeeklyDataLoading] = useState(false);

  const [weeklyData, setWeeklyData] = useState([]);

  const [showOnboarding, setShowOnboarding] = useState(false);

  const [isTrialUser, setIsTrialUser] = useState(false);

  const location = useLocation();
  const isLoginPage = location.pathname === '/login';

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(null);

  const [isDbInitialized, setIsDbInitialized] = useState(false);

  useEffect(() => {
    // Set a timeout to avoid indefinite loading
    const loadingTimeout = setTimeout(() => {
      if (isLoading) {
        console.log('Application loading timeout reached - forcing load');
        setIsLoading(false);
        setLoadingError('Loading timeout reached. Some features may not be available. Try refreshing the page.');
      }
    }, 15000); // 15 seconds timeout
    
    // Event handlers for database state
    const handleDbInitialized = () => {
      console.log('Database successfully initialized');
      setIsDbInitialized(true);
      setIsLoading(false);
      // Clear any previous errors
      setLoadingError(null);
    };
    
    const handleDbTimeout = (event) => {
      console.error('Database initialization timed out', event.detail);
      setIsDbInitialized(true); // We still want to render the app even if DB is not working
      setIsLoading(false);
      setLoadingError(
        'Database initialization timed out. This might be caused by having multiple tabs open. ' +
        'Try closing other tabs of this application and refreshing the page.'
      );
      toast.error('Database initialization timed out. Some features may not be available.');
    };
    
    const handleDbError = (event) => {
      console.error('Database error:', event.detail);
      setIsDbInitialized(true); // We still want to render the app even if DB is not working
      setIsLoading(false);
      setLoadingError(
        `Database error: ${event.detail?.message || 'Unknown error'}. ` +
        'Try clearing locks and refreshing the page.'
      );
      
      // Check if the error is a missing object store error
      if (event.detail?.error?.name === 'NotFoundError' || 
          (event.detail?.message && event.detail.message.includes('object store'))) {
        toast.error('Database error detected. Attempting to reset the database...', {
          duration: 5000
        });
        
        // After a delay, refresh the page so the user gets a fresh start
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        toast.error('Database error. Some features may not be available.', {
          duration: 3000
        });
      }
    };
    
    const handleDataSynced = () => {
      console.log('Data synced with server');
      // Only set loading to false if it's the initial sync
      if (isLoading) {
        setIsLoading(false);
      }
    };
    
    // Function to handle visibility change
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible' && loadingError) {
        console.log('Tab became visible again, checking if database is now accessible');
        // Dispatch a custom event to check DB state
        if (typeof window !== 'undefined') {
          window.dispatchEvent(new Event('app_check_db_state'));
        }
      }
    };
    
    // Listen for database events
    window.addEventListener('app_db_initialized', handleDbInitialized);
    window.addEventListener('app_db_timeout', handleDbTimeout);
    window.addEventListener('app_db_error', handleDbError);
    window.addEventListener('app_data_synced', handleDataSynced);
    
    // Listen for visibility changes (if the user switches back to the tab)
    document.addEventListener('visibilitychange', handleVisibilityChange);
    
    return () => {
      clearTimeout(loadingTimeout);
      window.removeEventListener('app_db_initialized', handleDbInitialized);
      window.removeEventListener('app_db_timeout', handleDbTimeout);
      window.removeEventListener('app_db_error', handleDbError);
      window.removeEventListener('app_data_synced', handleDataSynced);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [isLoading, loadingError]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  useEffect(() => {
    if (db) {
      const fetch_data = async () => {
        try {
          let list = await db.getAllItems()
          console.log('Raw data from database:', list)

          const foodItems = list.filter((item) => item.name)
          console.log('Filtered food items:', foodItems)

          const consumedFoodList = foodItems
            .map((item) => {
              const food = new ConsumedFood(item)
              if (item.imageBlob) {
                food.imageURL = URL.createObjectURL(item.imageBlob)
              }
              return food
            })
          console.log('Processed consumedFoodList:', consumedFoodList)

          setResponseList(consumedFoodList)

          // Load user profile
          const profileData = list.find((item) => item.type === 'userProfile')
          if (profileData) {
            console.log('Loaded user profile:', profileData)
            setUserProfile(profileData)
          }

          // Find the most recent item using the highest index number
          const recentItem = foodItems.reduce((mostRecent, current) => {
            return (current.id > (mostRecent?.id || 0)) ? current : mostRecent;
          }, null);

          if (recentItem) {
            const recentConsumedFood = new ConsumedFood(recentItem)
            if (recentItem.imageBlob) {
              recentConsumedFood.imageURL = URL.createObjectURL(recentItem.imageBlob)
            }
            setMostRecentItem(recentConsumedFood)
          } else {
            setMostRecentItem(null)
          }
        } catch (error) {
          console.error('Error fetching data:', error)
          setdbError(error.message)
        }
      }
      fetch_data()
    }
  }, [db, uploading, selectedDate, refreshSavedRecipesTrigger, refreshTrigger])

  const fetchWeeklyData = useCallback(async () => {
    if (db) {
      setIsWeeklyDataLoading(true);
      try {
        // Calculate the start and end dates for the selected week
        const today = new Date();
        const monday = new Date(today);
        
        // Adjust to Monday of the current week
        monday.setDate(monday.getDate() - monday.getDay() + (monday.getDay() === 0 ? -6 : 1));
        
        // Apply week offset
        monday.setDate(monday.getDate() + (currentWeekOffset * 7));
        monday.setHours(0, 0, 0, 0);
        
        const sunday = new Date(monday);
        sunday.setDate(monday.getDate() + 6);
        sunday.setHours(23, 59, 59, 999);

        console.log('Filtering items between:', monday.toLocaleString(), 'and', sunday.toLocaleString());

        // Use the new getWeeklyData method that filters by date and user_id
        const weeklyItems = await db.getWeeklyData(monday, sunday);

        // Process items and create URLs in a single pass
        const processedItems = weeklyItems.map(item => {
          const processedItem = new ConsumedFood(item);
          if (item.imageBlob) {
            processedItem.imageURL = URL.createObjectURL(item.imageBlob);
          }
          return processedItem;
        });

        setWeeklyConsumptionItems(processedItems);
      } catch (error) {
        console.error('Error fetching weekly data:', error);
      } finally {
        setIsWeeklyDataLoading(false);
      }
    }
  }, [db, currentWeekOffset]);

  useEffect(() => {
    fetchWeeklyData();
    // Clean up object URLs
    return () => {
      weeklyConsumptionItems.forEach(item => {
        if (item.imageURL && item.imageURL.startsWith('blob:')) {
          URL.revokeObjectURL(item.imageURL);
        }
      });
    };
  }, [currentWeekOffset, db]);

  const handleDateChange = (direction) => {
    if (direction === 'previous') {
      handlePreviousDay();
    } else if (direction === 'next') {
      handleNextDay();
    }
  };

  const handlePreviousDay = () => {
    const previousDay = new Date(selectedDate)
    previousDay.setDate(previousDay.getDate() - 1)
    setSelectedDate(previousDay)
  }


  const handleNextDay = () => {
    const nextDay = new Date(selectedDate)
    nextDay.setDate(nextDay.getDate() + 1)
    setSelectedDate(nextDay)
  }

  const handlePreviousWeek = () => {
    setCurrentWeekOffset(prev => prev - 1)
  }

  const handleNextWeek = () => {
    setCurrentWeekOffset(prev => prev + 1)
  }

  const handleDelete = async (itemId) => {
    // Immediately update UI state
    setWeeklyConsumptionItems(prevItems => prevItems.filter(item => item.id !== itemId));
    setResponseList(prevList => prevList.filter(item => item.id !== itemId));
    
    // Update database
    try {
      await db.deleteItem(itemId);
      // Refresh weekly data
      fetchWeeklyData();
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  const handleUpdate = async (updatedItem) => {
    if (db) {
      await db.updateItem(updatedItem)
      setResponseList((prevList) =>
        prevList.map((item) => (item.id === updatedItem.id ? updatedItem : item))
      )
      setRefreshTrigger(prev => prev + 1); // Trigger a refresh after update
    }
  }

  const handleProfileUpdate = async (profile) => {
    if (!user) {
      console.error('No authenticated user found');
      return;
    }

    try {
      // Ensure user_id and name are set
      const profileData = {
        ...profile,
        user_id: user.id,
        name: profile.name || 'User', // Ensure name is never empty
        updated_at: new Date().toISOString()
      };

      console.log('Updating profile with data:', profileData);

      // First check if a profile exists
      const { data: existingProfile, error: checkError } = await supabase
        .from('user_profiles')
        .select('id')
        .eq('user_id', user.id)
        .single();

      if (checkError && checkError.code !== 'PGRST116') {
        throw checkError;
      }

      // Create a copy without the 'type' field for Supabase
      const supabaseProfileData = { ...profileData };
      delete supabaseProfileData.type; // Remove type as it's not in the DB schema

      let result;
      if (existingProfile) {
        // Update existing profile
        result = await supabase
          .from('user_profiles')
          .update(supabaseProfileData)
          .eq('user_id', user.id)
          .select()
          .single();
      } else {
        // Insert new profile
        result = await supabase
          .from('user_profiles')
          .insert(supabaseProfileData)
          .select()
          .single();
      }

      if (result.error) throw result.error;

      // Update local state
      setUserProfile(result.data);
      
      // Also store in local DB for offline access
      if (db) {
        try {
          // Add type for local storage only
          const localProfileData = {
            ...result.data,
            type: 'userProfile'
          };
          
          await db.addItem(localProfileData);
          console.log('Profile saved to local DB');
        } catch (dbError) {
          console.error('Error saving profile to local DB:', dbError);
        }
      }
      
      return result.data;
    } catch (error) {
      console.error('Error updating profile:', error);
      throw error;
    }
  };

  const refreshSavedRecipes = useCallback(() => {
    setRefreshSavedRecipesTrigger(prev => prev + 1);
  }, []);

  const filteredResponseList = responseList
    .filter(
      (item) => new Date(item.uploadDateTime).toLocaleDateString() === selectedDate.toLocaleDateString()
    )
    .map(item => ({
      ...item,
      hasAllergyAlert: allergyAlert && allergyAlert.itemId === item.id,
      allergens: allergyAlert && allergyAlert.itemId === item.id ? allergyAlert.allergens : [],
      mealCategory: item.mealCategory || categorizeMealTime(item.uploadDateTime)
    }))
    .sort((a, b) => {
      // First sort by the actual upload time
      const timeA = new Date(a.uploadDateTime).getTime();
      const timeB = new Date(b.uploadDateTime).getTime();
      return timeA - timeB;
    });

  const handleWeekDateChange = (date) => {
    setSelectedWeekDate(date)
    // Calculate the week offset based on the selected date
    const today = new Date()
    const diffTime = date.getTime() - today.getTime()
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    const weekOffset = Math.floor(diffDays / 7)
    setCurrentWeekOffset(weekOffset)
  }

  // Modify the tabs array to include a mobile property
  const tabs = [
    { id: 'upload', label: 'Upload', icon: 'upload', showOnMobile: false },
    { id: 'history', label: 'History', icon: 'clock-history', showOnMobile: true },
    { id: 'recipes', label: 'Recipes', icon: 'book', showOnMobile: true },
    { id: 'purge', label: 'Purge', icon: 'trash', showOnMobile: true }
  ];

  const handleTabChange = (newTab) => {
    setActiveTab(newTab);
    // Reset to daily view when switching to history tab
    if (newTab === 'history') {
      setActiveNutritionSubTab('daily');
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      const currentIndex = tabs.find(tab => tab.id === activeTab).index;
      if (currentIndex < tabs.length - 1) {
        handleTabChange(tabs[currentIndex + 1].id);
      }
    },
    onSwipedRight: () => {
      const currentIndex = tabs.find(tab => tab.id === activeTab).index;
      if (currentIndex > 0) {
        handleTabChange(tabs[currentIndex - 1].id);
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  // useEffect(() => {
  //   if (typeof window !== 'undefined') {
  //     window.refreshApp = () => setPurgeTriggered(prev => prev + 1);
  //   }
  // }, []);

  const getCategoryIcon = (category) => {
    switch (category.toLowerCase()) {
      case 'breakfast': return 'sunrise-fill';
      case 'brunch': return 'egg-fried';
      case 'lunch': return 'sun-fill';
      case 'afternoon snack': return 'cup-straw';
      case 'dinner': return 'moon-fill';
      case 'night snack': return 'stars';
      default: return 'clock-fill';
    }
  };

  useEffect(() => {
    document.querySelectorAll('.consumption-history-item').forEach((item, index) => {
      item.style.setProperty('--animation-order', index);
    });
  }, [filteredResponseList]);

  // Updated function to format the date
  const formatCurrentDate = () => {
    const today = new Date();
    const selectedDateObj = new Date(selectedDate);

    if (activeNutritionSubTab === 'daily') {
      const diffDays = Math.round((selectedDateObj - today) / (1000 * 60 * 60 * 24));

      switch (diffDays) {
        case -1:
          return "Yesterday";
        case 0:
          return "Today";
        case 1:
          return "Tomorrow";
        default:
          const day = selectedDateObj.getDate().toString().padStart(2, '0');
          const month = (selectedDateObj.getMonth() + 1).toString().padStart(2, '0');
          return `${day}-${month}`;
      }
    }

    // For weekly view, return the formatted week range
    return formatWeekRange(currentWeekOffset);
  };

  // Add this function to format the week range
  const formatWeekRange = (weekOffset = 0) => {
    const today = new Date();
    const monday = new Date(today);
    monday.setDate(today.getDate() - (today.getDay() + 6) % 7 + (weekOffset * 7));
    const sunday = new Date(monday);
    sunday.setDate(sunday.getDate() + 6);

    switch (weekOffset) {
      case -1:
        return "Last Week";
      case 0:
        return "This Week";
      case 1:
        return "Next Week";
      default:
        const formatDate = (date) => {
          const day = date.getDate().toString().padStart(2, '0');
          const month = (date.getMonth() + 1).toString().padStart(2, '0');
          return `${day}-${month}`;
        };
        return `${formatDate(monday)} ~ ${formatDate(sunday)}`;
    }
  };

  // Add this function to calculate total calories for the day
  const calculateTotalCalories = (items) => {
    return items.reduce((total, item) => total + (item.calories || 0), 0);
  };

  const handleCalorieCardClick = (direction) => {
    if (direction === 'left') {
      handlePreviousDay();
    } else if (direction === 'right') {
      handleNextDay();
    }
  };

  const handleWeekChange = (offset) => {
    setCurrentWeekOffset(offset);
    console.log('Week offset changed to:', offset);
  };

  useEffect(() => {
    const addRippleEffect = (element) => {
      element.addEventListener('click', (e) => {
        element.classList.add('ripple-active');
        setTimeout(() => {
          element.classList.remove('ripple-active');
        }, 300);
      });
    };

    if (leftNavRef.current) addRippleEffect(leftNavRef.current);
    if (rightNavRef.current) addRippleEffect(rightNavRef.current);
  }, []);

  const toggleDrawer = () => {
    if (isDrawerOpen) {
      // Close the drawer
      setIsDrawerOpen(false);
      // Reset recent item to collapsed state
      setIsRecentItemExpanded(false);
    } else {
      // Open the drawer
      setIsDrawerOpen(true);
      // Reset scroll position to top
      if (drawerRef.current) {
        drawerRef.current.scrollTop = 0;
      }
    }
  };

  // Add this useEffect to handle window resize
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768 && activeTab === 'upload') {
        setActiveTab('history');
      }
    };

    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [activeTab]);

  const loadUserProfile = async () => {
    if (!user) return;
    
    try {
      // First check the local DB if available
      if (db) {
        console.log('Checking local DB for user profile');
        const localProfile = await db.checkUserProfile();
        if (localProfile) {
          console.log('Found profile in local DB:', localProfile);
          setUserProfile(localProfile);
          return;
        }
      }
      
      // If not found in local DB, check Supabase
      console.log('Checking Supabase for user profile');
      const { data, error } = await supabase
        .from('user_profiles')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (error && error.code !== 'PGRST116') {
        throw error;
      }

      if (data) {
        console.log('Found profile in Supabase:', data);
        setUserProfile(data);
        
        // Add to local DB for future offline use
        if (db) {
          try {
            await db.addItem({
              ...data,
              type: 'userProfile'
            });
            console.log('Saved Supabase profile to local DB');
          } catch (dbError) {
            console.error('Error saving Supabase profile to local DB:', dbError);
          }
        }
      }
    } catch (error) {
      console.error('Error loading user profile:', error);
    }
  };

  useEffect(() => {
    loadUserProfile();
  }, [user]);

  const checkOnboardingStatus = async () => {
    if (!user) return;
    
    try {
      console.log('Checking onboarding status for user:', user.id);
      
      // Check local storage first
      const hasCompletedOnboarding = localStorage.getItem(`onboarding_complete_${user.id}`);
      if (hasCompletedOnboarding === 'true') {
        console.log('Onboarding complete found in localStorage');
        setShowOnboarding(false);
        return;
      }

      console.log('Checking user profile in database');
      
      // If not in local storage, check if we have a userProfile in the database
      if (!db) {
        console.error('Database not initialized');
        setShowOnboarding(true);
        return;
      }
      
      // First try to get from local DB
      const profile = await db.checkUserProfile();
      
      if (profile) {
        console.log('User profile found in database:', profile);
        // If profile exists, mark onboarding as complete
        localStorage.setItem(`onboarding_complete_${user.id}`, 'true');
        setShowOnboarding(false);
        // Also update the userProfile state
        setUserProfile(profile);
      } else {
        // If no profile found, check directly in Supabase as a fallback
        console.log('No profile found in local DB, checking Supabase directly');
        const { data, error } = await supabase
          .from('user_profiles')
          .select('*')
          .eq('user_id', user.id)
          .single();
          
        if (error && error.code !== 'PGRST116') {
          console.error('Error checking Supabase for profile:', error);
        }
        
        if (data) {
          console.log('Profile found in Supabase:', data);
          localStorage.setItem(`onboarding_complete_${user.id}`, 'true');
          setShowOnboarding(false);
          setUserProfile(data);
          
          // Add to local DB for future offline use
          if (db) {
            try {
              await db.addItem({
                ...data,
                type: 'userProfile'
              });
            } catch (dbError) {
              console.error('Error saving Supabase profile to local DB:', dbError);
            }
          }
        } else {
          console.log('No profile found, showing onboarding');
          setShowOnboarding(true);
        }
      }
    } catch (error) {
      console.error('Error checking onboarding status:', error);
      // On error, show onboarding to ensure user can create a profile
      setShowOnboarding(true);
    }
  };

  const handleOnboardingComplete = async (profile) => {
    try {
      await handleProfileUpdate(profile);
      // Store completion in local storage
      localStorage.setItem(`onboarding_complete_${user.id}`, 'true');
      setShowOnboarding(false);
      toast.success('Profile setup complete!', {
        position: "top-right",
        duration: 3000
      });
    } catch (error) {
      console.error('Error completing onboarding:', error);
      toast.error('Error setting up profile. Please try again.', {
        position: "top-right",
        duration: 5000
      });
    }
  };

  // Clear onboarding status when user logs out
  const handleLogout = async () => {
    localStorage.removeItem(`onboarding_complete_${user.id}`);
    // ... rest of logout logic ...
  };

  useEffect(() => {
    if (user) {
      checkOnboardingStatus();
    }
  }, [user]);

  useEffect(() => {
    if (!user && TrialManager.hasTrialsRemaining()) {
      setIsTrialUser(true);
    } else {
      setIsTrialUser(false);
    }
  }, [user]);

  useEffect(() => {
    const { data: { subscription } } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'SIGNED_IN') {
        navigate('/');
      }
    });

    return () => subscription.unsubscribe();
  }, [navigate]);

  // If we're on the login page, show Auth component
  if (isLoginPage) {
    return <Auth />;
  }

  // If user is not authenticated and not a trial user, redirect to login
  if (!user && !isTrialUser) {
    return <Navigate to="/login" replace />;
  }

  // Show onboarding if needed
  if (showOnboarding) {
    return (
      <div className="onboarding-wrapper">
        <OnboardingFlow 
          onComplete={handleOnboardingComplete} 
          user={user} 
        />
      </div>
    );
  }

  // Modify the return statement to show a loading indicator
  if (isLoading && user) {
    return (
      <div className="loading-screen">
        <div className="spinner-container">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <p className="mt-3">Loading your data...</p>
          {loadingError && (
            <div className="alert alert-warning mt-3" role="alert">
              <p>{loadingError}</p>
              <div className="d-flex flex-column gap-2 mt-3">
                <button 
                  className="btn btn-sm btn-primary" 
                  onClick={() => window.location.reload()}
                >
                  <i className="bi bi-arrow-clockwise me-2"></i>
                  Retry
                </button>
                <button 
                  className="btn btn-sm btn-outline-secondary" 
                  onClick={() => {
                    // Clear local storage locks
                    if (typeof localStorage !== 'undefined') {
                      localStorage.removeItem('app_db_initializing');
                      localStorage.removeItem('app_db_init_time');
                      localStorage.removeItem('app_db_init_by');
                      localStorage.setItem('app_db_close_request', Date.now().toString());
                      
                      // Reload after a short delay to give time for cleanup
                      setTimeout(() => window.location.reload(), 500);
                    } else {
                      window.location.reload();
                    }
                  }}
                >
                  <i className="bi bi-trash me-2"></i>
                  Clear Locks & Retry
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  // Add this right before the return statement, after the loading check
  if (loadingError) {
    toast.error(loadingError, {
      position: "top-right",
      duration: 5000
    });
  }

  return (
    <Routes>
      <Route 
        path="/" 
        element={
          <div className="app">
            <div className='container-sm' style={{ maxWidth: '600px', margin: '0 auto' }}>
              <header className="d-flex justify-content-between align-items-center mb-4">
                <h1>What did you eat today?</h1>
                <button
                  className="btn btn-outline-secondary"
                  onClick={() => setActiveTab('profile')}
                >
                  <i className="bi bi-person"></i>
                </button>
              </header>

              {allergyAlert && (
                <div className="alert alert-danger alert-dismissible fade show mb-4" role="alert">
                  <h4 className="alert-heading">Allergy Warning!</h4>
                  <p><strong>This food may contain allergens you're sensitive to:</strong> {allergyAlert.allergens.join(', ')}.</p>
                  <hr />
                  <p className="mb-0">{allergyAlert.notes}</p>
                  <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setAllergyAlert(null)}></button>
                </div>
              )}

              <ul className="nav nav-tabs mb-4">
                {tabs.map((tab) => (
                  <li className={`nav-item ${tab.showOnMobile ? '' : 'd-none d-md-block'}`} key={tab.id}>
                    <button
                      className={`nav-link ${activeTab === tab.id ? 'active' : ''}`}
                      onClick={() => handleTabChange(tab.id)}
                      type="button"
                      data-tab={tab.id}
                    >
                      <i className={`bi bi-${tab.icon} me-2`}></i>
                      {tab.label}
                    </button>
                  </li>
                ))}
              </ul>

              <div {...handlers}>
                {activeTab === 'history' && (
                  <>
                    <h2 className="h4 mb-3 text-primary d-flex justify-content-between align-items-center">
                      <span>
                        <i className="bi bi-pie-chart me-2"></i>Nutrition Summary
                      </span>
                      <span className="badge bg-primary">{formatCurrentDate()}</span>
                    </h2>

                    <div className="d-flex justify-content-between align-items-center">
                      <div className="nutrition-tabs">
                        <ul className="nav nav-tabs">
                          <li className="nav-item">
                            <button
                              className={`nav-link ${activeNutritionSubTab === 'daily' ? 'active' : ''}`}
                              type="button"
                              onClick={() => setActiveNutritionSubTab('daily')}
                            >
                              Daily
                            </button>
                          </li>
                          <li className="nav-item">
                            <button
                              className={`nav-link ${activeNutritionSubTab === 'weekly' ? 'active' : ''}`}
                              type="button"
                              onClick={() => setActiveNutritionSubTab('weekly')}
                            >
                              Weekly
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>

                    {activeNutritionSubTab === 'daily' && (
                      <NutritionSummary
                        consumedFoodList={filteredResponseList}
                        rdi={userProfile?.rdi}
                        onDateChange={handleDateChange}
                        isDaily={true}
                        leftNavRef={leftNavRef}
                        rightNavRef={rightNavRef}
                        isExpanded={isCalorieCardExpanded}
                        setIsExpanded={setIsCalorieCardExpanded}
                      />
                    )}

                    {activeNutritionSubTab === 'weekly' && (
                      <>
                        <WeeklyNutritionSummary
                          userProfile={userProfile}
                          currentWeekOffset={currentWeekOffset}
                          onWeekChange={setCurrentWeekOffset}
                          leftNavRef={leftNavRef}
                          rightNavRef={rightNavRef}
                          isExpanded={isCalorieCardExpanded}
                          setIsExpanded={setIsCalorieCardExpanded}
                          weeklyData={weeklyConsumptionItems}
                          isLoading={isWeeklyDataLoading}
                        />

                      </>
                    )}

                    <h2 className="h4 mb-3 text-primary d-flex justify-content-between align-items-center">
                      <span>
                        <i className="bi bi-clock-history me-2"></i>
                        {activeNutritionSubTab === 'daily' ? 'Daily' : 'Weekly'} Consumption History
                      </span>
                    </h2>
                    <div className='consumption-history'>
                      {activeNutritionSubTab === 'daily' ? (
                        // Daily consumption history
                        ['Breakfast', 'Brunch', 'Lunch', 'Afternoon Snack', 'Dinner', 'Night Snack', 'Other'].map(category => {
                          const itemsInCategory = filteredResponseList.filter(item => item.mealCategory === category);
                          if (itemsInCategory.length === 0) return null;

                          return (
                            <div key={category} className="meal-category-section">
                              <h3 className="meal-category-title">
                                <i className={`bi bi-${getCategoryIcon(category)}`}></i>
                                <span>{category}</span>
                              </h3>
                              <div className="meal-items">
                                {itemsInCategory.map((item) => (
                                  <ConsumptionHistoryItem
                                    key={item.id}
                                    item={item}
                                    onDelete={handleDelete}
                                    onUpdate={handleUpdate}
                                    refreshSavedRecipes={refreshSavedRecipes}
                                    hasAllergyAlert={item.hasAllergyAlert}
                                    allergens={item.allergens}
                                  />
                                ))}
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        // Weekly consumption history
                        weeklyConsumptionItems.length > 0 ? (
                          weeklyConsumptionItems.map((item) => (
                            <ConsumptionHistoryItem
                              key={item.id}
                              item={item}
                              onDelete={handleDelete}
                              onUpdate={handleUpdate}
                              refreshSavedRecipes={refreshSavedRecipes}
                              hasAllergyAlert={item.hasAllergyAlert}
                              allergens={item.allergens}
                            />
                          ))
                        ) : (
                          <p>No items consumed this week.</p>
                        )
                      )}
                    </div>
                  </>
                )}

                {activeTab === 'upload' && (
                  <>
                    <UploadImage
                      setUploading={setUploading}
                      selectedDate={selectedDate}
                      userProfile={userProfile}
                      setAllergyAlert={setAllergyAlert}
                      refreshSavedRecipes={refreshSavedRecipes}
                    />
                    <div className="mt-4">
                      {mostRecentItem ? (
                        <ConsumptionHistoryItem
                          item={mostRecentItem}
                          onDelete={handleDelete}
                          onUpdate={handleUpdate}
                          refreshSavedRecipes={refreshSavedRecipes}
                          hasAllergyAlert={mostRecentItem.hasAllergyAlert}
                          allergens={mostRecentItem.allergens}
                        />
                      ) : (
                        <p>No items uploaded yet.</p>
                      )}
                    </div>
                  </>
                )}

                {activeTab === 'profile' && (
                  <UserProfile onProfileUpdate={handleProfileUpdate} userProfile={userProfile} />
                )}

                {activeTab === 'recipes' && (
                  <SavedRecipes key={refreshSavedRecipesTrigger} />
                )}

                {activeTab === 'micronutrients' && (
                  <MicronutrientsSummary consumedFoodList={filteredResponseList} rdi={userProfile?.rdi} />
                )}

                {activeTab === 'purge' && (
                  <Purge />
                )}
              </div>
            </div>

            {/* Replace the fixed bottom button with a sliding drawer */}
            <div className={`upload-drawer ${isDrawerOpen ? 'open' : ''}`} ref={drawerRef}>
              <div className="drawer-content">
                <button className="close-drawer" onClick={toggleDrawer}>
                  <i className="bi bi-x-lg"></i>
                </button>
                <UploadImage
                  setUploading={setUploading}
                  selectedDate={selectedDate}
                  userProfile={userProfile}
                  setAllergyAlert={setAllergyAlert}
                  refreshSavedRecipes={refreshSavedRecipes}
                />
                <div className="mt-4">
                  {mostRecentItem ? (
                    <ConsumptionHistoryItem
                      item={mostRecentItem}
                      onDelete={handleDelete}
                      onUpdate={handleUpdate}
                      refreshSavedRecipes={refreshSavedRecipes}
                      hasAllergyAlert={mostRecentItem.hasAllergyAlert}
                      allergens={mostRecentItem.allergens}
                      isExpanded={isRecentItemExpanded}
                      setIsExpanded={setIsRecentItemExpanded}
                    />
                  ) : (
                    <p>No items uploaded yet.</p>
                  )}
                </div>
              </div>
            </div>

            <div className="d-md-none fixed-bottom bg-light">
              <button
                className="btn btn-primary w-100"
                onClick={toggleDrawer}
              >
                <i className={`bi ${isDrawerOpen ? 'bi-chevron-down' : 'bi-upload'} me-2`}></i>
                {isDrawerOpen ? 'Hide' : 'Upload Food'}
              </button>
            </div>
          </div>
        } 
      />
      <Route path="/auth/callback" element={<AuthCallback />} />
      <Route path="/login" element={<Navigate to="/" replace />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  )
}

export default App

