import React, { useState, useEffect } from 'react';
import { nutritionTypes } from '../ConsumedFood';
import { useDB } from '../providers/DBProvider';

const getDefaultRDI = (nutrientKey) => {
  switch (nutrientKey) {
    case 'calories': return 2000;
    case 'protein': return 50;
    case 'carbohydrates': return 300;
    case 'fats': return 65;
    case 'fiber': return 30;
    case 'sugar': return 50;
    case 'cholesterol': return 300;
    case 'sodium': return 2300;
    default: return 100;
  }
};

const WeeklyNutritionSummary = ({ 
  userProfile, 
  currentWeekOffset, 
  onWeekChange, 
  leftNavRef, 
  rightNavRef, 
  isExpanded, 
  setIsExpanded,
  weeklyData,
  isLoading
}) => {
  const calculateTotalNutrition = () => {
    const totals = {
      calories: 0,
      macronutrients: {
        protein: 0,
        carbohydrates: 0,
        fats: 0,
        fiber: 0,
        sugar: 0
      },
      cholesterol: 0,
      sodium: 0
    };

    weeklyData.forEach(item => {
      totals.calories += Number(item.calories) || 0;
      
      if (item.macronutrients) {
        Object.keys(totals.macronutrients).forEach(nutrient => {
          totals.macronutrients[nutrient] += Number(item.macronutrients[nutrient]) || 0;
        });
      }
      
      totals.cholesterol += Number(item.cholesterol) || 0;
      totals.sodium += Number(item.sodium) || 0;
    });

    return totals;
  };

  const totalNutrition = calculateTotalNutrition();
  const dailyAverage = {
    calories: totalNutrition.calories / 7,
    macronutrients: Object.keys(totalNutrition.macronutrients).reduce((acc, nutrient) => {
      acc[nutrient] = totalNutrition.macronutrients[nutrient] / 7;
      return acc;
    }, {}),
    cholesterol: totalNutrition.cholesterol / 7,
    sodium: totalNutrition.sodium / 7,
  };

  const calculatePercentage = (value, total) => Math.min((value / total) * 100, 100);

  const formatWeekRange = (start, end) => {
    const formatDate = (date) => {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      return `${day}/${month}`;
    };
    return `${formatDate(start)} - ${formatDate(end)}`;
  };

  const nutrients = [
    { name: 'Calories', unit: 'kcal', rdiKey: 'calories', icon: '🔥' },
    { name: 'Protein', unit: 'g', rdiKey: 'protein', icon: '💪' },
    { name: 'Carbs', unit: 'g', rdiKey: 'carbohydrates', icon: '🍞' },
    { name: 'Fat', unit: 'g', rdiKey: 'fats', icon: '🥑' },
    { name: 'Fiber', unit: 'g', rdiKey: 'fiber', icon: '🥦' },
    { name: 'Sugar', unit: 'g', rdiKey: 'sugar', icon: '🍭' },
    { name: 'Cholesterol', unit: 'mg', rdiKey: 'cholesterol', icon: '🥚' },
    { name: 'Sodium', unit: 'mg', rdiKey: 'sodium', icon: '🧂' },
  ];

  return (
    <div className={`nutrition-dashboard ${isExpanded ? 'expanded' : 'collapsed'}`}>
      <div 
        className={`total-calories-display ${isLoading ? 'loading' : ''}`}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div ref={leftNavRef} className="calories-navigation left ripple" 
          onClick={(e) => { e.stopPropagation(); onWeekChange(currentWeekOffset - 1); }}>
          <i className="bi bi-chevron-left"></i>
        </div>
        <div ref={rightNavRef} className="calories-navigation right ripple" 
          onClick={(e) => { e.stopPropagation(); onWeekChange(currentWeekOffset + 1); }}>
          <i className="bi bi-chevron-right"></i>
        </div>
        
        <div className="calories-content">
          {isLoading ? (
            <div className="loading-skeleton">
              <div className="skeleton-circle"></div>
              <div className="skeleton-text">
                <div className="skeleton-line"></div>
                <div className="skeleton-line"></div>
              </div>
            </div>
          ) : (
            <>
              <div className="calories-circle" style={{ '--percentage': `${calculatePercentage(totalNutrition.calories, userProfile?.rdi?.calories * 7 || 14000)}%` }}>
                <div className="calories-inner">
                  <span className="calories-number">{totalNutrition.calories.toFixed(0)}</span>
                  <span className="calories-unit">kcal</span>
                </div>
              </div>
              <div className="calories-info">
                <h3>Weekly Calories</h3>
                <p>of {userProfile?.rdi?.calories * 7 || 14000} kcal weekly goal</p>
                <p className="calories-percentage">
                  {calculatePercentage(totalNutrition.calories, userProfile?.rdi?.calories * 7 || 14000).toFixed(1)}% consumed
                </p>
              </div>
            </>
          )}
        </div>

        <div className="expand-icon">
          <i className={`bi bi-chevron-${isExpanded ? 'up' : 'down'}`}></i>
        </div>
      </div>

      {isExpanded && (
        <>
          <div className="nutrient-summary compact">
            {isLoading ? (
              Array(8).fill(0).map((_, index) => (
                <div key={index} className="nutrient-item skeleton">
                  <div className="skeleton-icon"></div>
                  <div className="skeleton-content">
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                  </div>
                </div>
              ))
            ) : (
              nutrients.slice(1).map((nutrient) => {
                const total = nutrient.rdiKey === 'cholesterol' || nutrient.rdiKey === 'sodium'
                  ? totalNutrition[nutrient.rdiKey]
                  : totalNutrition.macronutrients[nutrient.rdiKey] || 0;
                const rdiValue = (userProfile?.rdi?.[nutrient.rdiKey] ?? getDefaultRDI(nutrient.rdiKey)) * 7;
                const percentage = calculatePercentage(total, rdiValue);

                return (
                  <div key={nutrient.name} className="nutrient-item">
                    <div className="nutrient-icon">{nutrient.icon}</div>
                    <div className="nutrient-info">
                      <div className="nutrient-name">{nutrient.name}</div>
                      <div className="nutrient-values">
                        <span>{total.toFixed(1)}/{rdiValue}{nutrient.unit}</span>
                        <span className="nutrient-percentage">{percentage.toFixed(0)}%</span>
                      </div>
                    </div>
                    <div className="nutrient-bar" style={{ width: `${percentage}%` }}></div>
                  </div>
                );
              })
            )}
          </div>

          <div className="total-calories-display mb-4">
            <div className="calories-content">
              <div className="calories-circle" style={{ '--percentage': `${calculatePercentage(dailyAverage.calories, userProfile?.rdi?.calories || 2000)}%` }}>
                <div className="calories-inner">
                  <span className="calories-number">{dailyAverage.calories.toFixed(0)}</span>
                  <span className="calories-unit">kcal</span>
                </div>
              </div>
              <div className="calories-info">
                <h3>Daily Average Calories</h3>
                <p>of {userProfile?.rdi?.calories || 2000} kcal daily goal</p>
                <p className="calories-percentage">
                  {calculatePercentage(dailyAverage.calories, userProfile?.rdi?.calories || 2000).toFixed(1)}% consumed
                </p>
              </div>
            </div>
          </div>

          <div className="nutrient-summary compact">
            {isLoading ? (
              Array(8).fill(0).map((_, index) => (
                <div key={index} className="nutrient-item skeleton">
                  <div className="skeleton-icon"></div>
                  <div className="skeleton-content">
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                  </div>
                </div>
              ))
            ) : (
              nutrients.slice(1).map((nutrient) => {
                const average = nutrient.rdiKey === 'cholesterol' || nutrient.rdiKey === 'sodium'
                  ? dailyAverage[nutrient.rdiKey]
                  : dailyAverage.macronutrients[nutrient.rdiKey] || 0;
                const rdiValue = userProfile?.rdi?.[nutrient.rdiKey] ?? getDefaultRDI(nutrient.rdiKey);
                const percentage = calculatePercentage(average, rdiValue);

                return (
                  <div key={nutrient.name} className="nutrient-item">
                    <div className="nutrient-icon">{nutrient.icon}</div>
                    <div className="nutrient-info">
                      <div className="nutrient-name">{nutrient.name}</div>
                      <div className="nutrient-values">
                        <span>{average.toFixed(1)}/{rdiValue}{nutrient.unit}</span>
                        <span className="nutrient-percentage">{percentage.toFixed(0)}%</span>
                      </div>
                    </div>
                    <div className="nutrient-bar" style={{ width: `${percentage}%` }}></div>
                  </div>
                );
              })
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default WeeklyNutritionSummary;
